import React, { Fragment } from "react";
import CustomCard from "../shared/CustomCard";
import { getImageUrl } from "../utils/commonFunctions";

export default function ImageCard({ col = 4, config }) {
  const _title = config?.title;
  const _path = config?.url;
  return (
    <Fragment>
      {config?.show && (
        <CustomCard col={col} title={_title}>
          <div className="text-center">
            <img
              className="p-4"
              src={getImageUrl(_path)}
              alt=""
              height={260}
              width="100%"
            />
          </div>
        </CustomCard>
      )}
    </Fragment>
  );
}
