import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../assets/images/backgroundImage.jpeg";
import logo from "../assets/images/logoLight.png";
import { useDispatch } from "react-redux";
import { showFormErrors } from "../utils/commonFunctions";
import { isAuthenticated } from "../services/auth";
import { onUserLoginAction } from "../store/actions/userActions";
import constants from "../constants";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    userName: "",
    password: "",
    rememberMe: true,
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value, formErrors: {} }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData)) {
      dispatch(onUserLoginAction(data, setLoading, navigate));
    }
  };

  const rememberMe = () => {
    setData((prev) => ({ ...prev, rememberMe: !prev.rememberMe }));
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="loginV2">
      <img src={background} alt="" className="background" />
      <div className="text-center h-full">
        <div className="flex flex-column justify-content-center h-full">
          <div className="w-11 md:w-4 lg:w-3 mx-auto my-2 login-form shadow-7">
            <img src={logo} alt="indus" className="mt-3" />
            <h2 className="my-2">Login</h2>
            <form onSubmit={onSubmit} className="form">
              <label htmlFor="username">User Name</label>
              <br />
              <input
                id="username"
                type="text"
                name="userName"
                value={data.userName}
                onChange={handleChange}
                required
              />
              <small className="p-error">{data?.formErrors?.userName}</small>
              <br /> <br />
              <label htmlFor="password">Password</label>
              <br />
              <div className="password-box">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                  required
                />
                <span
                  id="password-eye"
                  onClick={() => setShowPassword(!showPassword)}
                  className={showPassword ? "pi pi-eye-slash" : "pi pi-eye"}
                ></span>
              </div>
              <small className="p-error">{data?.formErrors?.password}</small>
              <br />
              <div className="mt-2 mx-1 flex">
                {data.rememberMe ? (
                  <i
                    className="pi pi-check-square my-auto"
                    onClick={rememberMe}
                  />
                ) : (
                  <i className="pi pi-stop my-auto" onClick={rememberMe} />
                )}
                <label className="my-auto ml-2" onClick={rememberMe}>
                  Remember Me
                </label>
              </div>
              <br />
              <div className="login-button">
                {loading && <i id="spinner" className="pi pi-spinner"></i>}
                <button disabled={loading} type="submit">
                  Login
                </button>
              </div>
            </form>
            <br />
            <small className="text-color-secondary">
              {constants.appName} {constants.appCopyright}
            </small>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
