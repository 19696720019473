import React from "react";

export default function CustomCard({
  children,
  header,
  className,
  title,
  col,
}) {
  return (
    <div className={`col-12 ${col ? `md:col-${col}` : "md:col"} p-2`}>
      <div className={`card shadow-5 h-full ${className}`}>
        <div className="flex justify-content-between">
          {title ? <div className="title">{title}</div> : null}
          {header}
        </div>

        {children}
      </div>
    </div>
  );
}

export function CustomCardSecondary({ children, className }) {
  return <div className={`secondary-card p-2 ${className}`}>{children}</div>;
}
