import React, { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { getImageUrl } from "../utils/commonFunctions";

const ImageWithLoader = ({
  url,
  maxHeight = "120px",
  height = "120px",
  width = "200px",
}) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div style={{ position: "relative", maxHeight, height, width }}>
      {loading && (
        <div style={styles.loader}>
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="2"
            animationDuration="1.5s"
          />
        </div>
      )}
      <img
        src={getImageUrl(url)}
        alt="content"
        style={{ ...styles.image, display: loading ? "none" : "block" }}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

const styles = {
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "4px",
  },
};

export default ImageWithLoader;
