import React from "react";
import CustomCard from "../../shared/CustomCard";
import Grid, { Col } from "../../shared/Grid";
import AnalogList from "../Analogs/AnalogList";
import PieChart from "../PieChart/PieChart";
import { Link } from "react-router-dom";
import { encodeUrl } from "../../utils/URLEncoding";

export default function ProjectCard({ project, live }) {
  const _live = live[project._id];
  const _title = project.name;
  const _pieChart = project?.config?.pieChart;
  const _analogs = project?.config?.analogs;

  const _alarmPage = project?.Pages?.find(
    (item) => item?.templateId === "TEMPLATE_5"
  );

  const _projectFirstPage = project?.Pages[0];

  let headers = () => {
    return (
      <Link
        to={
          _alarmPage
            ? `/project/${encodeUrl(project._id)}/${encodeUrl(_alarmPage._id)}`
            : ""
        }
      >
        <span className="text-red-500">
          <i className="pi pi-exclamation-triangle text-red-500" /> Attention
        </span>
      </Link>
    );
  };
  return (
    <CustomCard
      col={6}
      title={
        <Link
          to={
            _projectFirstPage
              ? `/project/${encodeUrl(project._id)}/${encodeUrl(
                  _projectFirstPage._id
                )}`
              : ""
          }
        >
          <div>{_title}</div>
        </Link>
      }
      header={headers()}
    >
      <Grid>
        <Col size={4}>
          {_pieChart?.show && (
            <PieChart config={_pieChart} data={_live} legendPosition="bottom" />
          )}
        </Col>
        <Col size={8}>
          {_analogs?.show && <AnalogList config={_analogs} data={_live} />}
        </Col>
      </Grid>
    </CustomCard>
  );
}
