import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatedList } from "react-animated-list";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import CustomCard from "../shared/CustomCard";

import Eye from "../assets/images/Eye.png";
import ImageContainer from "../shared/ImageContainer";

import { getNewsAction } from "../store/actions/newsActions";
import ImageWithLoader from "../shared/ImageWithLoader";

import { formatDate } from "../utils/commonFunctions";
export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNewsAction());
  }, [dispatch]);

  let latestNews = useSelector((state) => state.common.news);
  const [visible, setVisible] = useState(null);

  const NewsContainder = ({ item }) => {
    return (
      <div key={item._id}>
        {/* For PC  */}
        <div className="hidden md:flex news justify-content-start my-2">
          <ImageWithLoader url={item.image} />
          <div className="ml-2 my-auto">
            <p className="text-xs">{formatDate(item.time)}</p>
            <div className="text-xl">{item.title}</div>
            {discriptionTemplate(item.description, item)}
          </div>
        </div>
        {/* For Mobile  */}
        <div className="grid md:hidden my-2 p-2">
          <ImageWithLoader url={item.image} width="100%" />
          <div className="mt-2">
            <p className="text-xs">{formatDate(item.time)}</p>
            <div className="text-xl">{item.title}</div>
            {discriptionTemplate(item.description, item, 70)}
          </div>
        </div>
        <hr />
      </div>
    );
  };
  const discriptionTemplate = (description, item, length = 225) => {
    return (
      <>
        <p>
          {description.length > length
            ? description.slice(0, length) + "..."
            : description}
        </p>

        {description.length > length ? (
          <div
            className="text-sm underline cursor-pointer"
            onClick={() => setVisible(item)}
          >
            Read More
          </div>
        ) : null}
      </>
    );
  };
  return (
    <>
      <CustomCard col="12" title="News">
        {latestNews.length ? (
          <>
            <AnimatedList initialAnimationDuration={1500} animation={"slide"}>
              {latestNews.map((item) => (
                <NewsContainder key={item._id} item={item} />
              ))}
            </AnimatedList>
          </>
        ) : (
          <div className="text-center">
            <img src={Eye} alt="" width="70%" className="mx-auto" />
          </div>
        )}
      </CustomCard>

      <Dialog
        visible={visible}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        onHide={() => setVisible(false)}
        showHeader={false}
        dismissableMask={true}
      >
        {visible ? (
          <div className="pt-4">
            <ImageContainer src={visible?.image} />
            <p className="text-xs mt-2">{formatDate(visible.time)}</p>
            <div className="text-xl my-2">{visible?.title}</div>
            <p>{visible?.description}</p>
            <div className="text-center mt-4">
              <Button
                severity="secondary"
                onClick={() => setVisible(false)}
                label="Close"
              />
            </div>
          </div>
        ) : null}
      </Dialog>
    </>
  );
}
