import React, { Fragment } from "react";
import CustomCard from "../../shared/CustomCard";
import AnalogList from "./AnalogList";

export default function AnalogListCard({ col = 4, config, data }) {
  const _title = config?.title;
  return (
    <Fragment>
      {config?.show && (
        <CustomCard col={col} title={_title}>
          <AnalogList config={config} data={data} />
        </CustomCard>
      )}
    </Fragment>
  );
}
