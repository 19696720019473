import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import constants from "../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addOneNewsAction,
  onDeleteNewsAction,
} from "../store/actions/newsActions";
import {
  addNewAlertAction,
  addProjectLiveDataAction,
  addProjectDataMinuteAction,
  getProjectsAction,
  addProjectDataHourAction,
  addNewLiveAlertAction,
} from "../store/actions/projectActions";
import { getProfileAction } from "../store/actions/userActions";
const URL = constants.baseUrl;
const socket = io(URL);

export default function useSocket() {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [isConnected, setIsConnected] = useState(false);

  //   Change the status of socket connection (It will Connect the socket as soon as we open our app)
  useEffect(() => {
    socket.on("connect", () => {
      console.info("Socket Connected");
      setIsConnected(socket.connected);
    });
    socket.on("disconnect", () => {
      console.info("Socket Disconnected");
      setIsConnected(socket.connected);
    });
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  //This will emit only if we are logged in and we have a userID , With userID, user will join project rooms that are assigned to that particular user.
  useEffect(() => {
    if (isConnected) {
      if (profile?._id) {
        socket.emit("joinProjects", { userId: profile?._id });
      }
    }
  }, [profile?._id, isConnected]);

  //This will only listen to the news if user if assigned the news true status in profile.
  useEffect(() => {
    if (profile?.news) {
      socket.on("latestNews", (e) => {
        console.log("latestNews==>", e);
        dispatch(addOneNewsAction(e));
      });
      socket.on("deleteNews", (e) => {
        console.log("deleteNews==>", e);
        dispatch(onDeleteNewsAction(e));
      });
    }
    return () => {
      socket.off("latestNews");
      socket.off("deleteNews");
    };
  }, [profile, dispatch]);

  //This will listen to the live data emit for every project,
  //We are only emiting the data in particular project room, so here we only added the listeners, becasue this user is only added in assigned project rooms
  useEffect(() => {
    if (isConnected) {
      console.log("All Data Listeners Listining...");
      socket.off("rawJson");
      socket.off("rawJsonMinute");
      socket.off("alert");
      socket.off("liveAlert");
      socket.off("userUpdate");
      socket.off("projectUpdate");

      socket.on("rawJson", (e) => {
        dispatch(addProjectLiveDataAction(e));
      });
      socket.on("rawJsonMinute", (e) => {
        dispatch(addProjectDataMinuteAction(e));
      });
      socket.on("rawJsonHour", (e) => {
        console.log("rawJsonHour==>", e);
        dispatch(addProjectDataHourAction(e));
      });
      socket.on("alert", (e) => dispatch(addNewAlertAction(e)));
      socket.on("liveAlert", (e) => dispatch(addNewLiveAlertAction(e)));
      socket.on("userUpdate", (e) => {
        dispatch(getProfileAction());
        dispatch(getProjectsAction());
      });
      socket.on("projectUpdate", (e) => {
        dispatch(getProjectsAction());
      });
    }
  }, [dispatch, isConnected]);

  return { socket, isConnected };
}
