import api from "../../services/api";
import { authenticate, isAuthenticated } from "../../services/auth";
import endPoints from "../../services/endPoints";
import { setNews, showToastAction } from "../slices/commonSlice";
import { setToInitialState } from "../slices/projectSlice";
import { setUserProfile } from "../slices/userSlice";
import { uploadFile } from "./uploadFiles";

const onUserLoginAction = (data, loading, navigate) => async (dispatch) => {
  let payload = { userName: data.userName, password: data.password };
  loading(true);
  const res = await api("post", endPoints.LOGIN, payload);
  if (res.success) {
    let user = {
      _id: res.data._id,
      firstName: res.data.firstName,
      lastName: res.data.lastName,
    };
    let token = res.data.token;
    authenticate(token, data.rememberMe, () => {
      dispatch(setUserProfile(user));
      if (isAuthenticated()) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    });
  } else {
    dispatch(showToastAction({ type: "error", title: res.message }));
  }
  loading(false);
};

const getProfileAction = () => async (dispatch) => {
  const res = await api("get", endPoints.PROFILE);
  if (res.success) {
    dispatch(setUserProfile(res.data));
  }
};
const updateProfileAction = (data, setLoading) => async (dispatch) => {
  setLoading(true);
  data.image = await uploadFile(data.image);
  const res = await api("put", endPoints.PROFILE, data);
  if (res.success) {
    dispatch(getProfileAction());
  }
  setLoading(false);
};
const updatePasswordAction = (data, setLoading, next) => async (dispatch) => {
  setLoading(true);
  data.image = await uploadFile(data.image);
  const res = await api("post", endPoints.CHANGE_PASSWORD, data);
  next(res);
  setLoading(false);
};

const onLogoutAction = () => async (dispatch) => {
  dispatch(setUserProfile({}));
  dispatch(setToInitialState());
  dispatch(setNews([]));
};

const onFcmTokenUpdateAction =
  ({ token }) =>
  async (dispatch) => {
    const res = await api("post", endPoints.FCM, { token });
  };

const onFcmTokenDeleteAction =
  ({ token }) =>
  async (dispatch) => {
    const res = await api("delete", endPoints.FCM, { token });
  };

export {
  onUserLoginAction,
  getProfileAction,
  updateProfileAction,
  updatePasswordAction,
  onLogoutAction,
  onFcmTokenUpdateAction,
  onFcmTokenDeleteAction,
};
