import React, { useEffect, useRef } from "react";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { useNavigate, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { logout } from "../services/auth";
import { PrivateRoutes } from "../routes/allRoutes";
import useProject from "../hooks/useProject";
import useTheme from "../hooks/useTheme";
import { getProfileAction, onLogoutAction } from "../store/actions/userActions";
import { getImageUrl } from "../utils/commonFunctions";
import iconUrl from "../assets/images/icon.png";
import logo from "../assets/images/logoLight2.png";
import moment from "moment";

export default function Topbar({ toggleSidebar, socket }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const webMenu = useRef(null);
  const mobileMenu = useRef(null);

  const { project, projectName, pageName } = useProject();

  const { selectedTheme, onChangeTheme } = useTheme();

  useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch]);

  const getPageTitle = () => {
    let route = PrivateRoutes.find((item) => item.path === location.pathname);
    let _routeName = route?.name;
    let _projectName = projectName ? projectName : "";
    let _pageName = pageName ? " - " + pageName : "";
    return <>{_routeName ? _routeName : _projectName + _pageName}</>;
  };

  const { profile } = useSelector((state) => state.user);
  let username = profile?.firstName || "Loading...";
  let userimage = profile?.image;

  let WebMenuItems = [
    {
      label: "Profile Setting",
      command: () => navigate("/profile"),
    },
    {
      icon: "pi pi-power-off",
      label: "Logout",
      command: () => {
        logout(() => {
          socket.disconnect();
          dispatch(onLogoutAction());
          navigate("/");
          socket.connect();
        });
      },
    },
  ];

  let mobileMenuItems = [
    {
      icon:
        selectedTheme === "dark" ? "pi pi-sun text-xl" : "pi pi-moon text-xl",
      label: "Theme",
      command: () => {
        if (selectedTheme === "dark") {
          onChangeTheme("light");
        } else {
          onChangeTheme("dark");
        }
      },
    },
    {
      icon: "pi pi-map-marker text-xl",
      label: "Sites",
      command: () => navigate("/map"),
    },
    {
      icon: "pi pi-power-off",
      label: "Logout",
      command: () => {
        logout(() => navigate("/"));
      },
    },
    {
      separator: true,
    },
    {
      template: () => {
        return (
          <div
            className="w-full flex align-items-center px-3"
            onClick={() => navigate("/profile")}
          >
            {userimage ? (
              <Avatar
                image={getImageUrl(userimage)}
                shape="circle"
                className="my-auto"
              />
            ) : (
              <Avatar
                image={iconUrl}
                size="small"
                shape="square"
                className="my-auto"
              />
            )}
            <div className="flex flex-column align">
              <span className="text-base text-dark ml-2">{username}</span>
            </div>
          </div>
        );
      },
    },
  ];

  const { live } = useSelector((state) => state.project);
  const _live = live[project?._id];
  let lastSync = _live
    ? moment().diff(moment(_live?.time), "days") > 0
      ? moment(_live?.time).format("MMM Do, hh:mm:ss A")
      : moment(_live?.time).format("hh:mm:ss A")
    : "";
  return (
    <div className="layout-topbar">
      <div className="flex">
        {/* for Web */}
        <div className="sidebar-top-web">
          <div className="toggle-btn ml-3" onClick={toggleSidebar}>
            <i className="pi pi-bars text-xl" />
          </div>
          <img
            src={logo}
            alt="logo"
            className="mx-auto topbar-logo"
            onClick={() => navigate("/home")}
          />
        </div>
        {/* for mobile  */}
        <div className="sidebar-top-mobile">
          <div className="toggle-btn ml-3" onClick={toggleSidebar}>
            <i className="pi pi-bars text-xl" />
          </div>
        </div>

        {/* Page Title  */}
        <div className="page-title my-auto text-base font-semibold">
          {getPageTitle()}
        </div>
      </div>

      {lastSync && (
        <div className="text-sm font-light my-auto mr-2 text-light md:hidden">
          {lastSync}
        </div>
      )}

      <div className="top-menu-web">
        {lastSync && (
          <div className="text-sm font-light my-auto mr-2 text-light">
            Sync: {lastSync}
          </div>
        )}
        <div
          className="map"
          onClick={
            selectedTheme === "dark"
              ? () => onChangeTheme("light")
              : () => onChangeTheme("dark")
          }
        >
          <i
            className={
              selectedTheme === "dark"
                ? "pi pi-sun text-xl"
                : "pi pi-moon text-xl"
            }
          />
        </div>
        <div className="map ml-2" onClick={() => navigate("/map")}>
          <i className="pi pi-map-marker text-xl" />
        </div>

        <div
          className="user"
          onClick={(event) => webMenu.current.toggle(event)}
        >
          {userimage ? (
            <Avatar
              image={getImageUrl(userimage)}
              shape="circle"
              className="my-auto"
            />
          ) : (
            <Avatar
              image={iconUrl}
              size="small"
              shape="square"
              className="my-auto"
            />
          )}
          <div className="flex">
            <div className="flex m-2 my-auto ">
              <span className="text-base text-light">{username}</span>
              <i className="pi pi-angle-down mt-1 px-2 text-light" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="top-menu-mobile mr-3"
        onClick={(event) => mobileMenu.current.toggle(event)}
      >
        <i className="pi pi-ellipsis-v text-xl"></i>
      </div>
      <Menu
        model={WebMenuItems}
        popup
        ref={webMenu}
        id="popup_menu_right"
        popupAlignment="right"
      />

      <Menu
        model={mobileMenuItems}
        popup
        ref={mobileMenu}
        id="popup_menu_right"
        popupAlignment="right"
      />
    </div>
  );
}
