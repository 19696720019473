import React, { useMemo, useState } from "react";
import {
  CustomCalander,
  CustomCheckboxs,
  CustomDropDown,
} from "../shared/AllInputs";
import { Button } from "primereact/button";
import { useEffect } from "react";
import moment from "moment";
import Grid, { Col } from "../shared/Grid";
import { useSelector } from "react-redux";
import { exportToExcel } from "../utils/excelHelper";
import formValidation from "../utils/validations";
import { showFormErrors } from "../utils/commonFunctions";
import { getProjectReportAction } from "../store/actions/projectActions";

const rangeOptions = [
  { name: "Day", value: "DAY" },
  { name: "Week", value: "WEEK" },
  { name: "Year", value: "YEAR" },
  { name: "Custom", value: "CUSTOM" },
];
const intervalOptions = [
  { name: "Hourly", value: "HOURLY" },
  { name: "Daily", value: "DAILY" },
];

export default function Reports2() {
  let { projects } = useSelector((state) => state.project);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    project: {},
    subPages: {},
    range: "",
    interval: "",
    dataToExport: [],
  });

  useEffect(() => {
    if (data.range) {
      let startDate = new Date(moment().subtract(1, "days"));
      let endDate = new Date();

      switch (data.range) {
        case "CUSTOM":
          startDate = new Date(moment().subtract(30, "days"));
          break;
        case "WEEK":
          startDate = new Date(moment().subtract(7, "days"));
          break;
        case "YEAR":
          startDate = new Date(moment().subtract(365, "days"));
          break;
        default:
          break;
      }
      setData((prev) => ({
        ...prev,
        startDate,
        endDate,
      }));
    }
  }, [data.range]);
  useEffect(() => {
    if (data.project) {
      setData((prev) => ({
        ...prev,
        subPages: {},
      }));
    }
  }, [data.project]);
  useEffect(() => {
    if (data.subPages) {
      setData((prev) => ({
        ...prev,
        dataToExport: prev.subPages.config
          ? Object.values(prev.subPages.config)
          : [],
      }));
    }
  }, [data.subPages]);

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const pageOptions = useMemo(() => data.project.Pages || [], [data.project]);
  const dataToExportOptions = useMemo(
    () => (data.subPages.config ? Object.values(data.subPages.config) : []),
    [data.subPages]
  );

  const handleExport = () => {
    if (showFormErrors(data, setData)) {
      getProjectReportAction(data, setLoading, (res) => {
        if (res.success) {
          exportToExcel(data, res.data);
        }
      });
    }
  };
  return (
    <Grid>
      <CustomDropDown
        name="project"
        data={data}
        options={projects}
        onChange={handleChange}
        placeholder="Select Project"
      />
      <CustomDropDown
        name="subPages"
        data={data}
        options={pageOptions}
        onChange={handleChange}
        placeholder="Select Subpage"
      />
      <CustomDropDown
        name="range"
        data={data}
        options={rangeOptions}
        onChange={handleChange}
        placeholder="Select Range"
      />
      {data?.range === "CUSTOM" && (
        <Col size={7} extraClassNames="grid m-0 p-0">
          <CustomCalander
            data={data}
            name="startDate"
            onChange={handleChange}
            maxDate={data.endDate}
            minDate={new Date(moment().subtract(365, "days"))}
          />
          <CustomCalander
            data={data}
            name="endDate"
            onChange={handleChange}
            minDate={data.startDate}
            maxDate={new Date()}
          />
        </Col>
      )}
      <CustomDropDown
        name="interval"
        data={data}
        options={intervalOptions}
        onChange={handleChange}
        placeholder="Select Interval"
      />
      {dataToExportOptions.length > 0 && (
        <CustomCheckboxs
          name="dataToExport"
          data={data}
          options={dataToExportOptions}
          onChange={handleChange}
        />
      )}
      <Col size={7}>
        <Button
          loading={loading}
          onClick={handleExport}
          severity="primary"
          label="Export"
          size="small"
        />
      </Col>
    </Grid>
  );
}
