import { useEffect, useState } from "react";
import { theme } from "../utils/theme";
import { useSelector } from "react-redux";

export default function useAppColors() {
  let { selectedTheme } = useSelector((state) => state.common);
  const [appColors, setAppColors] = useState({});

  useEffect(() => {
    setAppColors(theme[selectedTheme]);
  }, [selectedTheme]);

  return {
    appColors,
    theme: selectedTheme,
    light: theme.light,
    dark: theme.dark,
  };
}
