import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Template1 } from "../assets/images/svg/template/template1.svg";
import { ReactComponent as Template2 } from "../assets/images/svg/template/template2.svg";
import { ReactComponent as Template3 } from "../assets/images/svg/template/template3.svg";
import { ReactComponent as Template4 } from "../assets/images/svg/template/template4.svg";
import { ReactComponent as Template5 } from "../assets/images/svg/template/template5.svg";
import { ReactComponent as Template6 } from "../assets/images/svg/template/template3.svg";
import { ReactComponent as Template7 } from "../assets/images/svg/template/template2.svg";
import { ReactComponent as Template8 } from "../assets/images/svg/template/template4.svg";
import { ReactComponent as Template9 } from "../assets/images/svg/template/template9.svg";
import { ReactComponent as Template10 } from "../assets/images/svg/template/template10.svg";
import { ReactComponent as Template11 } from "../assets/images/svg/template/template11.svg";
import { decodeUrl, encodeUrl } from "../utils/URLEncoding";

export default function MenuItem({ item, TabLink }) {
  const [isOpen, setIsOpen] = useState(false);
  let { projectId } = useParams();
  projectId = decodeUrl(projectId);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getSvg = (templateId) => {
    switch (templateId) {
      case "TEMPLATE_1":
        return <Template1 />;
      case "TEMPLATE_2":
        return <Template2 />;
      case "TEMPLATE_3":
        return <Template3 />;
      case "TEMPLATE_4":
        return <Template4 />;
      case "TEMPLATE_5":
        return <Template5 />;
      case "TEMPLATE_6":
        return <Template6 />;
      case "TEMPLATE_7":
        return <Template7 />;
      case "TEMPLATE_8":
        return <Template8 />;
      case "TEMPLATE_9":
        return <Template9 />;
      case "TEMPLATE_10":
        return <Template10 />;
      case "TEMPLATE_11":
        return <Template11 />;
      default:
        return null;
    }
  };
  return (
    <>
      <Link
        className={`tab py-1 ${projectId === item._id ? "active" : ""}`}
        onClick={handleToggle}
      >
        <i
          className={`pi ${
            isOpen ? "pi-angle-down" : "pi-angle-right"
          } text-xl`}
        />
        &nbsp; {item.name}
      </Link>

      {isOpen && (
        <div className="sidebar-header">
          {item.Pages.map((page, i) => (
            <TabLink
              key={i}
              name={page.name}
              path={`/project/${encodeUrl(item._id)}/${encodeUrl(page._id)}`}
              icon="pi-arrow-right"
              svg={getSvg(page.templateId)}
              iconSize="text-xs"
              extraClassName="pl-3 submenu"
            />
          ))}
        </div>
      )}
    </>
  );
}
