export function encodeUrl(str) {
  try {
    if (str) {
      return btoa(str)
        ?.replace(/\+/g, "-")
        ?.replace(/\//g, "_")
        ?.replace(/=+$/, "");
    }
  } catch (error) {
    console.log(error);
    return;
  }
}

export function decodeUrl(str) {
  try {
    if (str) {
      const base64 = str?.replace(/-/g, "+")?.replace(/_/g, "/");
      return atob(base64);
    }
  } catch (error) {
    console.log(error);
    return;
  }
}
