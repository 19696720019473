import React, { useEffect, useRef } from "react";
import Grid from "../shared/Grid";
import CustomCard from "../shared/CustomCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment/moment";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { CustomCalander } from "../shared/AllInputs";
import { useState } from "react";
import formValidation from "../utils/validations";
import { showFormErrors } from "../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { getProjectsAlertsAction } from "../store/actions/projectActions";

export default function Template5({ project, alert = [] }) {
  const dispatch = useDispatch();
  const dateOverlay = useRef(null);
  const buttonRef = useRef(null);
  const statusOverlay = useRef(null);

  const [data, setData] = useState({
    startDate: new Date(moment().subtract(30, "days")),
    endDate: new Date(),
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (project._id) {
      dispatch(getProjectsAlertsAction({ ...data, project: project._id }));
    } // eslint-disable-next-line
  }, [project._id]);

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData)) {
      dispatch(
        getProjectsAlertsAction(
          { ...data, project: project._id },
          setLoading,
          () => {
            buttonRef.current?.click();
          }
        )
      );
    }
  };

  const rowClass = (data) => {
    let classname = "";
    switch (data.type) {
      case 1:
        classname = "bg-red-500 text-white";
        break;
      case 2:
        classname = "bg-yellow-500 text-white";
        break;
      case 3:
        classname = "bg-primary text-white";
        break;
      default:
        break;
    }
    return classname;
  };

  return (
    <div className="alarm">
      <Grid>
        <CustomCard col={12}>
          <div className="flex flex-wrap justify-content-between mb-3">
            <div
              ref={buttonRef}
              className="dateButton cursor-pointer"
              onClick={(e) => dateOverlay.current.toggle(e)}
            >
              Select Date
            </div>
            <i
              className="pi pi-info-circle cursor-pointer my-auto"
              onClick={(e) => statusOverlay.current.toggle(e)}
            />
          </div>
          <DataTable
            className=""
            size="small"
            value={alert}
            tableStyle={{ minWidth: "50rem" }}
            rowClassName={rowClass}
            paginator
            rows={10}
            scrollable
          >
            <Column header="Tag" field="tag" style={{ minWidth: "200px" }} />
            <Column
              header="In Time"
              field="inTime"
              style={{ minWidth: "230px" }}
            />
            <Column
              header="Out Time"
              field="outTime"
              style={{ minWidth: "230px" }}
            />
            <Column
              header="Ack"
              field="ackTime"
              style={{ minWidth: "230px" }}
            />
            <Column header="Value" field="value" />
            <Column header="Trigger" field="trigger" />
            <Column header="Unit" field="unit" />
            <Column header="Repeat" field="repeat" />
            <Column
              header="Description"
              field="description"
              style={{ minWidth: "230px" }}
            />
          </DataTable>
        </CustomCard>
      </Grid>

      <OverlayPanel ref={dateOverlay}>
        <Grid>
          <CustomCalander
            data={data}
            name="startDate"
            onChange={handleChange}
            maxDate={data.endDate}
            minDate={new Date(moment().subtract(365, "days"))}
          />
          <CustomCalander
            data={data}
            name="endDate"
            onChange={handleChange}
            minDate={data.startDate}
            maxDate={new Date()}
          />
          <Button
            loading={loading}
            label="Apply"
            severity="secondary"
            className="m-2"
            raised
            onClick={onSubmit}
          />
        </Grid>
      </OverlayPanel>
      <OverlayPanel ref={statusOverlay}>
        <div className="px-2">
          <div className="flex justify-content-between my-2">
            <div className="mx-2 my-auto">Low</div>
            <div className="indicator bg-primary my-auto"></div>
          </div>
          <div className="flex justify-content-between my-2">
            <div className="mx-2 my-auto">Medium</div>
            <div className="indicator bg-yellow-500 my-auto"></div>
          </div>
          <div className="flex justify-content-between my-2">
            <div className="mx-2 my-auto">High</div>
            <div className="indicator bg-red-500 my-auto"></div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
}
