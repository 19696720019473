import React, { Fragment } from "react";
import AnalogGrid from "../charts/Analogs/AnalogGrid";

export default function Template9({ page, live }) {
  const _config = page?.config;
  const _live = live;
  const _analogs = _config?.analogs;
  return (
    <Fragment>
      <AnalogGrid config={_analogs} data={_live} />
    </Fragment>
  );
}
