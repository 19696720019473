import React, { Fragment } from "react";
import CustomCard from "../../shared/CustomCard";
import PieChart from "./PieChart";

export default function PieChartCard({
  col = 4,
  config,
  data,
  legendPosition,
}) {
  const _title = config?.title;
  return (
    <Fragment>
      {config?.show && (
        <CustomCard col={col} title={_title}>
          <div className="text-center">
            <PieChart
              config={config}
              data={data}
              legendPosition={legendPosition}
              height="255px"
            />
          </div>
        </CustomCard>
      )}
    </Fragment>
  );
}
