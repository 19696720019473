import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { addNews, deleteNews, setNews } from "../slices/commonSlice";

const getNewsAction = () => async (dispatch) => {
  const res = await api("get", endPoints.NEWS);
  if (res.success) {
    dispatch(setNews(res.data));
  }
};
const addOneNewsAction = (res) => async (dispatch) => {
  if (res.success) {
    dispatch(addNews(res.data));
  }
};
const onDeleteNewsAction = (res) => async (dispatch) => {
  if (res.success) {
    dispatch(deleteNews(res.data));
  }
};

export { getNewsAction, addOneNewsAction, onDeleteNewsAction };
