import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getLineChartGrouped } from "../../utils/chartFunctions";
import { useEffect } from "react";
import useAppColors from "../../hooks/useAppColors";

export default function LineChart({
  config,
  data,
  height = 250,
  extraOptions = {},
  type = "line",
  dashed = false,
}) {
  const { appColors, theme } = useAppColors();
  const _keys = useMemo(() => config.items || [], [config.items]);
  const _data = data;

  const _yAxis = config.yAxis;
  const _height = height;

  const [grouped, setGrouped] = useState([]);

  useEffect(() => {
    let grouped = getLineChartGrouped(_keys, _data);
    if (grouped?.length) {
      grouped = grouped.reverse();
      setGrouped(grouped);
    }
  }, [_data, _keys]);

  useEffect(() => {
    setGrouped((prev) => {
      if (prev.length < _data?.length) {
        let item = _data[0];
        let grouped = getLineChartGrouped(_keys, [item]);
        return [...prev, ...grouped];
      } else {
        return [...prev];
      }
    });
  }, [_data, _data?.length, _keys]);

  let colors = [];
  let series = [];
  _keys.forEach((item) => {
    colors.push(item?.color);
    series.push({
      name: item?.title,
      data: grouped.map((keys) => ({ x: keys.x, y: keys[item?.value] })),
    });
  });

  let options = {
    chart: {
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      dropShadow: {
        enabled: true,
        opacity: 0.1,
        blur: 1,
        left: 0,
        top: 5,
        color: "#818589",
      },
      //   toolbar: {
      //     show: false,
      //   },
      //   zoom: {
      //     enabled: false,
      //   },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      dashArray: dashed ? 5 : 0,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      title: {
        // show: false,
        // text: _xAxis?.title,
        // style: {
        //   color: appColors.textPrimary,
        // },
      },
      labels: {
        // format: "hTT",
        style: {
          colors: appColors.textSecondary,
        },
      },
    },
    yaxis: {
      // stepSize: 20,
      title: {
        text: _yAxis?.title,
        style: {
          color: appColors.textPrimary,
        },
      },
      labels: {
        style: {
          colors: appColors.textSecondary,
        },
      },
    },

    tooltip: {
      theme: theme,
      x: {
        format: "dd MMM, HH:mm",
      },
    },
    legend: {
      position: "bottom",
    },
    ...extraOptions,
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        height={_height}
        width={"100%"}
      />
    </div>
  );
}
