import { RadialGauge } from "react-canvas-gauges";
import useAppColors from "../../hooks/useAppColors";

const Gauge = ({ config, data }) => {
  const { appColors } = useAppColors();
  const _data = data;
  const _value = _data?.["rawJson"][config.value] || 0;
  const _units = config.units;
  const _title = config.title;
  const _range = config.range;
  const _theme = {
    primary: appColors.bgPrimary,
    secondary: appColors.textPrimary,
    font_dark: appColors.textSecondary,
    font_unit: "#fd7e14",
    font: appColors.textPrimary,
  };

  let min, max, min1, max1, min2, max2, min3, max3;
  [min, max, min1, max1, min2, max2, min3, max3] = _range;
  const _center = (max - min) / 2;
  const _minCenter = (_center - min) / 2 + parseFloat(min);
  const _maxCenter = (max - _center) / 2 + _center;
  const _rangeArray = [
    parseFloat(min).toFixed(2),
    parseFloat(_minCenter).toFixed(2),
    parseFloat(_center).toFixed(2),
    parseFloat(_maxCenter).toFixed(2),
    parseFloat(max).toFixed(2),
  ];

  return (
    <div className="text-center">
      {_theme?.primary ? (
        <RadialGauge
          width={230}
          height={260}
          title={_title}
          units={_units}
          minValue={min}
          maxValue={max}
          majorTicks={_rangeArray}
          minorTicks={9}
          strokeTicks={true}
          highlights={[
            {
              from: min1,
              to: max1,
              color: `#4cd137`,
            },
            {
              from: min2,
              to: max2,
              color: `#fbc531`,
            },
            {
              from: min3,
              to: max3,
              color: `#e84118`,
            },
          ]}
          value={_value}
          colorMajorTicks={_theme.secondary}
          colorMinorTicks={_theme.secondary}
          colorTitle={_theme.font_dark}
          colorUnits={_theme.font_unit}
          colorNumbers={_theme.font}
          colorPlate={_theme.primary}
          colorPlateEnd={_theme.primary}
          borderShadowWidth={0}
          borders={false}
          needleType="arrow"
          needleColor="red"
          needleWidth={3}
          needleCircleSize={7}
          needleCircleOuter={true}
          needleCircleInner={false}
        />
      ) : (
        <div style={{ height: "280px" }}></div>
      )}
    </div>
  );
};
export default Gauge;
