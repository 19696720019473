import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import Topbar from "./Topbar";
import Sidebar from "./Sidebar";

import {
  getProjectsAction,
  getProjectsDataMinuteAction,
  getProjectsDataDayAction,
  getProjectsDataHourAction,
  getProjectsLastDataAction,
  getLiveAlertAction,
} from "../store/actions/projectActions";
import { setUserProjects } from "../store/slices/projectSlice";

export default function Layout({ socket, children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": !isSidebarOpen,
    "layout-static": isSidebarOpen,
    "layout-mobile-sidebar-active": mobileMenuActive,
  });

  useEffect(() => {
    if (!isDesktop()) {
      setIsSidebarOpen(false);
    }
  }, []);

  useEffect(() => {
    if (!isDesktop()) {
      setIsSidebarOpen(false);
      setMobileMenuActive(false);
      addClass(document.body, "body-overflow-hidden");
    }
  }, [location?.pathname]);

  const toggleSidebar = () => {
    if (isDesktop()) {
      setIsSidebarOpen((prev) => !prev);
    } else {
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
        setMobileMenuActive(false);
        addClass(document.body, "body-overflow-hidden");
      } else {
        setIsSidebarOpen(true);
        setMobileMenuActive(true);
        removeClass(document.body, "body-overflow-hidden");
      }
    }
  };
  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };
  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  // Load initial data when user logged into the app
  useEffect(() => {
    dispatch(getProjectsAction());
    dispatch(getLiveAlertAction());
    dispatch(getProjectsLastDataAction());
    dispatch(getProjectsDataMinuteAction());
    dispatch(getProjectsDataHourAction());
    dispatch(getProjectsDataDayAction());
  }, [dispatch]);
  //This will check localstorage for already saved projects config soo that on reload it will not wait for api response,
  //however it will change according to latest api response,
  useEffect(() => {
    let _projects = localStorage.getItem("projects");
    if (_projects) {
      _projects = JSON.parse(_projects);
      dispatch(setUserProjects(_projects));
    }
  }, [dispatch]);

  return (
    <div className={wrapperClass}>
      <Topbar toggleSidebar={toggleSidebar} socket={socket} />
      <Sidebar />
      <div className="layout-main-container">
        <div className="layout-main">{children}</div>
      </div>
    </div>
  );
}
