import { entries, notEqual, values } from "./javascript";
import formValidation from "./validations";
import constants from "../constants";
import moment from "moment";

const showFormErrors = (data, setData, ignore) => {
  let formErrors = {};
  entries(data).forEach(([key, value]) => {
    formErrors = {
      ...formErrors,
      ...formValidation(key, value, data, ignore),
    };
  });

  setData({ ...data, formErrors });
  return !values(formErrors).some((v) => notEqual(v, ""));
};

const capitalizeCamelCase = (str) => {
  let words = str.split(/(?=[A-Z])/);
  let capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  let capitalizedString = capitalizedWords.join(" ");
  return capitalizedString;
};

const getImageUrl = (image) => {
  if (image.includes("http")) {
    return image;
  } else {
    return constants.baseUrl + image;
  }
};

function getValue(data, string) {
  // Check if the string contains square brackets
  if (string.includes("[") && string.includes("]")) {
    // Extract the key name from within the square brackets
    var key = string.substring(string.indexOf("[") + 1, string.indexOf("]"));
    // Check if the key exists in the data object
    if (data && data?.rawJson?.hasOwnProperty(key)) {
      return data.rawJson[key];
    } else {
      return null; // Return null if key is not found in data
    }
  } else {
    return string; // Return the same string if no square brackets are found
  }
}

function formatDate(date) {
  const _date = moment(date);
  const _formattedDate = _date.format("MMM DD, YYYY hh:mm A");
  return _formattedDate;
}

export {
  capitalizeCamelCase,
  showFormErrors,
  getImageUrl,
  getValue,
  formatDate,
};
