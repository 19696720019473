import React, { Fragment, useMemo } from "react";
import CustomCard from "../../shared/CustomCard";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import LineChart from "./LineChart";
import AdvancedFilter from "../AdvancedFilter";

export default function LineChartCard({
  col,
  config,
  dataHour,
  dataDay,
  extraOptions,
  type,
  filters = true,
  view = "daily",
  height,
}) {
  const _title = config?.title;

  const [viewType, setViewType] = useState(view);

  const filter = (
    <div className="flex">
      <AdvancedFilter config={config} type="LINE" />
      <Dropdown
        className="custom-dropdown-filter p-inputtext-sm"
        value={viewType}
        onChange={(e) => setViewType(e.value)}
        options={[
          { name: "Daily", value: "daily" },
          { name: "Weekly", value: "weekly" },
          { name: "Monthly", value: "monthly" },
        ]}
        optionLabel="name"
      />
    </div>
  );

  const data = useMemo(() => {
    if (viewType === "daily") {
      return dataHour;
    } else if (viewType === "weekly") {
      return [...dataDay].splice(0, 7);
    } else {
      return dataDay;
    }
  }, [viewType, dataHour, dataDay]);

  return (
    <Fragment>
      {config?.show && (
        <CustomCard col={col} title={_title} header={filters && filter}>
          <LineChart
            viewType={viewType}
            config={config}
            data={data}
            extraOptions={extraOptions}
            type={type}
            height={height}
          />
        </CustomCard>
      )}
    </Fragment>
  );
}
