import React, { Fragment, useEffect, useMemo } from "react";
import AlarmList from "../charts/AlarmList";
import Grid from "../shared/Grid";
import GaugeCard from "../charts/Gauges/GaugeCard";
import ImageCard from "../charts/ImageCard";
import LineChartCard from "../charts/LineChart/LineChartCard";
import AnalogListCard from "../charts/Analogs/AnalogListCard";
import IndicatorListCard from "../charts/Indicator/IndicatorListCard";
import { getProjectsAlertsAction } from "../store/actions/projectActions";
import { useDispatch } from "react-redux";

export default function Template7({
  project,
  page,
  dataMinute = [],
  dataHour = [],
  dataDay = [],
  alert = [],
  live,
}) {
  const dispatch = useDispatch();
  const _live = live;
  const _dataMinute = dataMinute;
  const _dataHour = dataHour;
  const _dataDay = dataDay;
  const _config = page?.config;
  const _gauge = _config?.gauge;
  const _image = _config?.image;
  const _lineChart = _config?.lineChart;
  const _indicators = _config?.indicators;
  const _analogs = _config?.analogs;
  const _alarmList = _config?.alarmList;

  useEffect(() => {
    if (project._id) {
      dispatch(getProjectsAlertsAction({ project: project._id }));
    } // eslint-disable-next-line
  }, [project._id]);

  let colCount = useMemo(() => {
    let count = 12;
    if (_gauge?.show) {
      count -= 4;
    }
    if (_image?.show) {
      count -= 4;
    }
    return count;
  }, [_image?.show, _gauge?.show]);
  return (
    <Fragment>
      <Grid>
        <GaugeCard config={_gauge} data={_live} />
        <ImageCard config={_image} />
        <LineChartCard
          config={_lineChart}
          dataMinute={_dataMinute}
          dataHour={_dataHour}
          dataDay={_dataDay}
          filters
          col={colCount}
        />
      </Grid>
      <Grid>
        <IndicatorListCard config={_indicators} data={_live} />
        <AnalogListCard config={_analogs} data={_live} />
        <AlarmList config={_alarmList} alert={alert} />
      </Grid>
    </Fragment>
  );
}
