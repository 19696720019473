import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { ProtectedRoutes } from "./routes/ProtectedRoutes";
import Forbidden from "./pages/Forbidden";
import Login from "./pages/Login";
import useSocket from "./hooks/useSocket";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { generateToken, messaging } from "./services/firebase";
import { isAuthenticated } from "./services/auth";
import {
  onFcmTokenDeleteAction,
  onFcmTokenUpdateAction,
} from "./store/actions/userActions";
// import { onMessage } from "firebase/messaging";

function App() {
  const dispatch = useDispatch();
  const { socket } = useSocket();
  // const { project } = useSelector((state) => state);
  // console.log(project);
  let authenticated = isAuthenticated();
  useEffect(() => {
    if (authenticated) {
      updateToken();
    } else {
      deleteToken();
    }
  }, [authenticated]);

  const updateToken = async () => {
    let token = await generateToken();
    if (token) {
      dispatch(onFcmTokenUpdateAction({ token }));
    }
  };
  const deleteToken = async () => {
    let token = await generateToken();
    if (token) {
      dispatch(onFcmTokenDeleteAction({ token }));
    }
  };

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/403" element={<Forbidden />} />
        </Routes>
        <ProtectedRoutes socket={socket} />
      </HashRouter>
    </div>
  );
}

export default App;
