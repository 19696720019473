import React from "react";
import Grid from "../shared/Grid";
import useProject from "../hooks/useProject";
import { useSelector } from "react-redux";
import ProjectCard from "../charts/ProjectCard/ProjectCard";

export default function Dashboard() {
  const { rawProjects } = useProject();
  const { live } = useSelector((state) => state.project);

  return (
    <Grid>
      {rawProjects.map((project, i) => (
        <ProjectCard key={i} project={project} live={live} />
      ))}
    </Grid>
  );
}
