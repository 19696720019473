import React from "react";
import { CustomCardSecondary } from "../../shared/CustomCard";

export default function AnalogList({ config, data, listSize = 5 }) {
  const _list = config?.items;
  const _data = data && data["rawJson"];

  return (
    <div
      className={`scrollable-card ${_list?.length > listSize ? "pr-2" : ""}`}
    >
      {_list.length &&
        _list.map((item, i) => (
          <CustomCardSecondary key={i} className="mt-2">
            <div className="flex justify-content-between">
              <div className="my-auto w-8">{item.title}</div>
              <div className="my-auto w-4 flex">
                {item.value ? (
                  <span className="greenValue w-8 text-right pr-1">
                    {_data && _data[item.value]}
                  </span>
                ) : null}
                {item.units ? (
                  <span className="yellowUnit w-4 my-auto">{item.units}</span>
                ) : null}
              </div>
            </div>
          </CustomCardSecondary>
        ))}
    </div>
  );
}
