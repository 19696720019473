import React from "react";
import LogoLight from "../assets/images/logoLight.png";
import LogoDark from "../assets/images/logo.png";
import useTheme from "../hooks/useTheme";
import constants from "../constants";

export default function AppInfoSection({ headers, children }) {
  const { selectedTheme } = useTheme();
  return (
    <div className="grid m-0 p-0">
      <div className="col-11 md:col-5 mx-auto ">
        {headers}
        <div className="text-center my-3 line-height-3">
          {selectedTheme === "dark" ? (
            <img src={LogoLight} alt="" className="mx-auto" />
          ) : (
            <img src={LogoDark} alt="" className="mx-auto" />
          )}
          {children}
          <p>Version: {constants.appVersion}</p> <p>Powered by FLOVEL</p>
          <p>{constants.appCopyright}</p>
          <div className="flex justify-content-center mt-3">
            <i
              onClick={() => window.open(constants.website, "_blank")}
              className="mx-3 text-2xl cursor-pointer pi pi-globe"
            ></i>
            <i
              onClick={() => window.open(constants.facebook, "_blank")}
              className="mx-3 text-2xl cursor-pointer pi pi-facebook"
            ></i>
            <i
              onClick={() => window.open(constants.linkedin, "_blank")}
              className="mx-3 text-2xl cursor-pointer pi pi-linkedin"
            ></i>
            <i
              onClick={() => window.open(constants.whatsapp, "_blank")}
              className="mx-3 text-2xl cursor-pointer pi pi-whatsapp"
            ></i>
            <i
              onClick={() => window.open(constants.youtube, "_blank")}
              className="mx-3 text-3xl cursor-pointer pi pi-youtube"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}
