import React, { Fragment } from "react";
import Grid from "../../shared/Grid";
import CustomCard, { CustomCardSecondary } from "../../shared/CustomCard";

export default function BigAnalogs({ config, data }) {
  const _config = config;
  const _list = _config?.items;
  return (
    <Fragment>
      {_config?.show && (
        <Grid>
          {_list?.map((item, i) => (
            <SingleAnalog key={i} config={item} data={data} />
          ))}
        </Grid>
      )}
    </Fragment>
  );
}

function SingleAnalog({ col = 4, config, data }) {
  let _data = data;
  const _title = config["title"];
  const _value = _data && _data["rawJson"][config.value];
  const _units = config.units;
  return (
    <CustomCard col={col}>
      <CustomCardSecondary className="text-center">
        <div className="mt-2 mb-1 title">{_title}</div>
        <div className="mb-2">
          <span className="greenValue">{_value} </span>
          <span className="yellowUnit"> {_units}</span>
        </div>
      </CustomCardSecondary>
    </CustomCard>
  );
}
