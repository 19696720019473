import moment from "moment";
const calculateMean = (arr) => {
  if (arr.length === 0) return 0;
  const sum = arr.reduce((acc, val) => acc + val, 0);
  let mean = sum / arr.length;
  mean = parseInt(mean);
  return mean;
};
const groupData = (data, hourDuration = 10) => {
  //To get the time of last updated data
  const currentTime = moment(data[0]?.time);
  const groupedData = {};
  for (let i = hourDuration - 1; i >= 0; i--) {
    let _hourKey = currentTime.clone().subtract(i, "hours").format("hA");
    groupedData[_hourKey] = {
      values: [],
    };
  }
  data.forEach((item) => {
    let _itemTime = moment(item.time);
    if (
      _itemTime.isAfter(currentTime.clone().subtract(hourDuration, "hours"))
    ) {
      let _hourKey = _itemTime.format("hA");
      groupedData[_hourKey].values.push(item.rawJson);
    }
  });
  return groupedData;
};
//For Pie Chart
const getPieChartOptions = (dataKeys) => {
  const labels = [];
  const colors = [];
  dataKeys.forEach((item) => {
    labels.push(item.title);
    if (item.color) {
      colors.push(item.color);
    }
  });
  return { labels, colors };
};
const getPieChartSeries = (dataKeys, data) => {
  const series = [];
  if (data) {
    data = data.rawJson;
    dataKeys.forEach((item) => {
      series.push(data[item.value] || 0);
    });
  }
  return { series };
};
//For Line Chart
const getLineChartGrouped = (dataKeys, data) => {
  let _groupedData = data?.map((item) => {
    let _values = {};
    dataKeys.forEach((key) => {
      _values[key?.value] = item.rawJson[key?.value];
    });
    return {
      //converting it to local time so that licne chart automatically format it
      x: moment(item?.time).local(true).format("YYYY-MM-DDTHH:mm:ss") + "Z",
      ..._values,
    };
  });
  return _groupedData;
};
//For Bar Chart
const getBarChartSeries = (dataKeys, data) => {
  let _groupedData = groupData(data);
  let _keys = [];
  let _series = dataKeys.map((item) => {
    _keys.push(item.value);
    return {
      name: item.title,
      data: [],
      color: item.color,
    };
  });
  let _categories = Object.keys(_groupedData);
  Object.values(_groupedData).forEach((item) => {
    _keys.forEach((key, i) => {
      let mean = calculateMean(item.values.map((value) => value[key]));
      _series[i].data.push(mean);
    });
  });
  return { categories: _categories, series: _series };
};

export {
  getBarChartSeries,
  groupData,
  calculateMean,
  getPieChartOptions,
  getPieChartSeries,
  getLineChartGrouped,
};
