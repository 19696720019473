import React, { useMemo } from "react";
import { Chart } from "react-google-charts";
import {
  getPieChartOptions,
  getPieChartSeries,
} from "../../utils/chartFunctions";
import useAppColors from "../../hooks/useAppColors";

export default function PieChart({ config, data, height }) {
  const _data = data;
  const _keys = useMemo(() => config.items || [], [config.items]);
  const { series } = useMemo(
    () => getPieChartSeries(_keys, _data),
    [_keys, _data]
  );
  const { labels, colors } = useMemo(() => getPieChartOptions(_keys), [_keys]);

  const { appColors } = useAppColors();

  const options = {
    is3D: true,
    backgroundColor: {
      fill: "transparent",
    },
    legend: {
      position: "bottom",
      textStyle: {
        color: appColors?.textPrimary,
        fontSize: 12,
      },
    },
    chartArea: {
      left: 0,
      top: 0,
      width: "100%",
      height: "90%",
    },
    pieSliceText: "value",
  };

  if (labels.length === colors.length) {
    options.colors = colors;
  }

  let _chartData = [
    ["Label", "Value", { role: "tooltip", type: "string" }],
    ...labels.map((label, i) => [
      `${label}`,
      // `${label} (${_keys[i]?.units})`,
      { v: series[i], f: `${series[i]}${_keys[i]?.units}` },
      `${label}: ${series[i]}${_keys[i]?.units}`,
    ]),
  ];

  return (
    <Chart
      chartType="PieChart"
      data={_chartData}
      options={options}
      width={"100%"}
      height={height}
    />
  );
}
