import React, { Fragment } from "react";
import CustomCard from "../../shared/CustomCard";
import Gauge from "./Gauge";
import Grid, { Col } from "../../shared/Grid";
import { Carousel } from "primereact/carousel";

export default function Gauges({ col = 12, config, data }) {
  const _title = config?.title;
  const _items = config?.items;
  const _data = data;
  const _status = _data?.["rawJson"][config?.status] || 0;

  const productTemplate = (product) => {
    return <Gauge config={product} data={_data} />;
  };

  let headers = () => {
    return (
      <>
        {_status ? (
          <span className="text-green-600 border-1 border-green-600 my-auto py-1 px-4 border-round-md">
            {_status}
          </span>
        ) : null}
      </>
    );
  };

  return (
    <Fragment>
      {config?.show && (
        <Grid>
          <CustomCard col={col} title={_title} header={headers()}>
            <div className="hidden lg:block">
              <Grid>
                {_items &&
                  _items.map((config, i) => (
                    <>
                      {config?.show && (
                        <Col key={i}>
                          <Gauge config={config} data={_data} />
                        </Col>
                      )}
                    </>
                  ))}
              </Grid>
            </div>

            <div className="block lg:hidden gauges">
              <Carousel
                value={_items}
                numScroll={1}
                numVisible={2}
                itemTemplate={productTemplate}
                circular
                autoplayInterval={5000}
                responsiveOptions={[
                  {
                    breakpoint: "767px",
                    numVisible: 2,
                    numScroll: 1,
                  },
                  {
                    breakpoint: "575px",
                    numVisible: 1,
                    numScroll: 1,
                  },
                ]}
              />
            </div>
          </CustomCard>
        </Grid>
      )}
    </Fragment>
  );
}
