import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import MenuItem from "./MenuItem";
import { ReactComponent as Home } from "../assets/images/svg/Home.svg";
import { ReactComponent as Reports } from "../assets/images/svg/Reports.svg";
import { ReactComponent as Projects } from "../assets/images/svg/Projects.svg";

import constants from "../constants";

export default function Sidebar() {
  const location = useLocation();
  const getActive = (path) => {
    return path === location.pathname;
  };

  let { projects } = useSelector((state) => state.project);
  let { profile } = useSelector((state) => state.user);

  let TabLink = ({
    path,
    name,
    icon,
    extraClassName,
    iconSize = "text-xl",
    svg,
  }) => {
    return (
      <Link
        to={path}
        className={`tab py-1 ${
          getActive(path) ? "active" : ""
        } ${extraClassName}`}
      >
        {svg ? svg : <i className={`pi ${icon} ${iconSize}`} />} &nbsp;
        {name}
      </Link>
    );
  };

  return (
    <div className="layout-sidebar">
      <div className="menu-bar">
        <div className="scroll-menu">
          <div className="sidebar-header">
            {profile?.news && (
              <TabLink name="Home" path="/home" svg={<Home />} />
            )}
            <TabLink name="All Projects" path="/dashboard" svg={<Projects />} />
            {projects.map((item, i) => (
              <MenuItem key={i} item={item} TabLink={TabLink} />
            ))}
            {profile?.reports && (
              <TabLink name="Reports" path="/reports" svg={<Reports />} />
            )}
          </div>
        </div>
        <div className="sidebar-footer mt-4">
          <div className="app-name py-2">
            <h2 className="text-xl">{constants.appName}</h2>
            <p className="text-sm"> Version: {constants.appVersion}</p>
          </div>
          <p className="my-2 text-sm">
            Remote Monitoring System <br />
            {}
          </p>
          <p className="mt-2 text-xs ">
            <Link to="/help" className="link">
              Help
            </Link>{" "}
            |{" "}
            <Link to="/about" className="link">
              About
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
