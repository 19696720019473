import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { decodeUrl } from "../utils/URLEncoding";

export default function useProject() {
  let { projectId, pageId } = useParams();
  projectId = decodeUrl(projectId);
  pageId = decodeUrl(pageId);

  let { projects } = useSelector((state) => state.project);

  let _projects = projects.map((item) => ({
    _id: item._id,
    name: item.name,
    description: item.description,
    latitude: item.latitude,
    longitude: item.longitude,
    config: item.config,
    mobiles: item.mobiles,
  }));

  let [project, setProject] = useState({});
  let [page, setPage] = useState({});

  useEffect(() => {
    let _project = projects.find((item) => item._id === projectId);
    let _page = _project?.Pages.find((item) => item._id === pageId);
    setProject(_project);
    setPage(_page);
  }, [projectId, pageId, projects]);

  return {
    project,
    page,
    projectName: project?.name,
    pageName: page?.name,
    projects: _projects,
    rawProjects: projects,
  };
}
