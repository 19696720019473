import { useEffect } from "react";
import { theme } from "../utils/theme";
import { useSelector, useDispatch } from "react-redux";
import { setThemeAction } from "../store/slices/commonSlice";
// import { changeThemeAction } from "../store/actions/profileAction";

export default function useTheme() {
  const dispatch = useDispatch();
  let { selectedTheme } = useSelector((state) => state.common);

  useEffect(() => {
    let currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      dispatch(setThemeAction(currentTheme));
    }
  }, [dispatch]);

  useEffect(() => {
    changeTheme({ name: selectedTheme });
  }, [selectedTheme]);

  const changeTheme = ({ name = "dark" }) => {
    const root = document.documentElement;
    root.style.setProperty("--bg-color-primary", theme[name].bgPrimary);
    root.style.setProperty("--bg-color-secondary", theme[name].bgSecondary);
    root.style.setProperty("--text-color-primary", theme[name].textPrimary);
    root.style.setProperty("--text-color-secondary", theme[name].textSecondary);
  };

  const onChangeTheme = (name) => {
    localStorage.setItem("theme", name);
    dispatch(setThemeAction(name));
  };

  return { selectedTheme, onChangeTheme };
}
