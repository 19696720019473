import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  projects: [],
  //live will contain projectId as key and last live data obj
  live: {},
  //data will contain projectId as key and array of data for every min
  dataMinute: {},
  //dataHour will contain projectId as key and array of data for every hour
  dataHour: {},
  //dataDay will contain projectId as key and array of data for every day
  dataDay: {},
  //alert will contain projectId as key and array of data for every project
  alert: {},
  liveAlert: {},
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setUserProjects: (state, action) => {
      state.projects = action.payload;
    },
    //For every 5s socket and for initial load api
    setProjectsLiveData: (state, action) => {
      let _obj = action.payload;
      let _projectId = _obj.project;
      state.live[_projectId] = _obj;
    },

    //will set data grouped by project for every minute recieved from the api at initial load
    setProjectsDataMinute: (state, action) => {
      state.dataMinute = action.payload;
    },
    //for every one minute it will add data in dataKey recieved from socket
    addProjectsDataMinute: (state, action) => {
      let _obj = action.payload;
      let _projectId = _obj.project;
      if (state?.dataMinute[_projectId]) {
        state.dataMinute[_projectId].unshift(_obj);
      } else {
        state.dataMinute[_projectId] = [action.payload];
      }
    },

    //will set data grouped by project for every hour from the api at initial load
    setProjectsDataHour: (state, action) => {
      state.dataHour = action.payload;
    },
    addProjectsDataHour: (state, action) => {
      let _obj = action.payload;
      let _projectId = _obj.project;

      if (state?.dataHour[_projectId]) {
        state.dataHour[_projectId].unshift(_obj);
      } else {
        state.dataHour[_projectId] = [action.payload];
      }
    },

    //will set data grouped by project for every day from the api at initial load
    setProjectsDataDay: (state, action) => {
      state.dataDay = action.payload;
    },

    setProjectsAlerts: (state, action) => {
      state.alert = action.payload;
    },
    addProjectAlert: (state, action) => {
      let _alert = action.payload;
      let _projectId = _alert[0].project;
      if (state?.alert[_projectId]) {
        state.alert[_projectId].unshift(..._alert);
      } else {
        state.alert[_projectId] = action.payload;
      }
    },
    setProjectsLiveAlerts: (state, action) => {
      state.liveAlert = action.payload;
    },
    addProjectsLiveAlerts: (state, action) => {
      let _obj = action.payload;
      let _projectId = _obj.project;
      state.liveAlert[_projectId] = _obj.alerts;
    },
    //It will be used on logout the clear all the previous states
    setToInitialState: (state) => {
      state.projects = [];
      state.live = {};
      state.data = {};
      state.dataHour = {};
      state.dataDay = {};
      state.alert = {};
    },
  },
});
export const {
  setUserProjects,
  setProjectsLiveData,

  setProjectsDataMinute,
  addProjectsDataMinute,

  setProjectsDataHour,
  addProjectsDataHour,

  setProjectsDataDay,

  setProjectsAlerts,
  addProjectAlert,

  setProjectsLiveAlerts,
  addProjectsLiveAlerts,

  setToInitialState,
} = projectSlice.actions;
export default projectSlice.reducer;
