import api from "../../services/api";
import endPoints from "../../services/endPoints";
import {
  setUserProjects,
  setProjectsLiveData,
  setProjectsDataMinute,
  addProjectsDataMinute,
  setProjectsDataHour,
  addProjectsDataHour,
  setProjectsDataDay,
  setProjectsAlerts,
  addProjectAlert,
  setProjectsLiveAlerts,
  addProjectsLiveAlerts,
} from "../slices/projectSlice";

// For Generate Reports
const getProjectReportAction = async (data, setLoading, next) => {
  setLoading(true);

  let payload = {
    project: data.project._id,
    interval: data.interval,
    startDate: data.startDate,
    endDate: data.endDate,
  };
  const res = await api("post", endPoints.PROJECT_REPORT, payload);
  setLoading(false);
  next(res);
};

// This will get all the projects assigned to that user
const getProjectsAction = () => async (dispatch) => {
  const res = await api("get", endPoints.PROJECT);
  if (res.success) {
    dispatch(setUserProjects(res.data));
    localStorage.setItem("projects", JSON.stringify(res.data));
  }
};

//This will fetch only the last record from live data collection on initial load.
const getProjectsLastDataAction = () => async (dispatch) => {
  const res = await api("get", endPoints.PROJECT_LAST_RAWJSON);
  if (res.success) {
    res.data.forEach((item) => {
      dispatch(setProjectsLiveData(item.lastElement));
    });
  }
};
//This Will get data from socket at every 5s and will replace the live data project obj with the new recived.
const addProjectLiveDataAction = (res) => async (dispatch) => {
  if (res.success) {
    dispatch(setProjectsLiveData(res.data));
  }
};

//Will get data from API for every minute from minutes data collection on initial load.
const getProjectsDataMinuteAction = () => async (dispatch) => {
  const res = await api("get", endPoints.PROJECT_RAWJSON);
  if (res.success) {
    let payload = {};
    res.data.forEach((item) => {
      payload[item._id] = item.list;
    });
    dispatch(setProjectsDataMinute(payload));
  }
};
//This Will get data from socket at every 1minute and will push the data into project_minute obj.
const addProjectDataMinuteAction = (res) => async (dispatch) => {
  if (res.success) {
    dispatch(addProjectsDataMinute(res.data));
  }
};

//Will get data from API for every hour from hour data collection on initial load.
const getProjectsDataHourAction = () => async (dispatch) => {
  const res = await api("get", endPoints.PROJECT_HOUR_RAWJSON);
  if (res.success) {
    let payload = {};
    res.data.forEach((item) => {
      payload[item._id] = item.list;
    });
    dispatch(setProjectsDataHour(payload));
  }
};
//This Will get data from socket at every 1Hour and will push the data into project_hour obj.
const addProjectDataHourAction = (res) => async (dispatch) => {
  if (res.success) {
    dispatch(addProjectsDataHour(res.data));
  }
};

//Will get data from API for every Day from day data collection on initial load.
const getProjectsDataDayAction = () => async (dispatch) => {
  const res = await api("get", endPoints.PROJECT_DAY_RAWJSON);
  if (res.success) {
    let payload = {};
    res.data.forEach((item) => {
      payload[item._id] = item.list;
    });
    dispatch(setProjectsDataDay(payload));
  }
};

//Will get all the alerts for user group by projectID from API
const getProjectsAlertsAction =
  (data, setLoading, next) => async (dispatch) => {
    if (setLoading) {
      setLoading(true);
    }
    let { project, startDate, endDate } = data;

    startDate = new Date(startDate).setHours(0, 0, 0, 0);
    endDate = new Date(endDate).setHours(23, 59, 59, 999);

    const res = await api("post", endPoints.PROJECT_ALERT, {
      project,
      startDate,
      endDate,
    });
    if (res.success) {
      let payload = {};
      payload[project] = res.data;
      dispatch(setProjectsAlerts(payload));
      if (next) {
        next();
      }
    }
    if (setLoading) {
      setLoading(false);
    }
  };

// Will get the multiple alerts for one projectID from socket.
const addNewAlertAction = (res) => async (dispatch) => {
  if (res.success) {
    dispatch(addProjectAlert(res.data));
  }
};

const getLiveAlertAction = (res) => async (dispatch) => {
  const res = await api("get", endPoints.PROJECT_LIVE_ALERT);
  if (res.success) {
    let payload = {};
    res.data.forEach((item) => {
      payload[item._id] = item.list;
    });
    dispatch(setProjectsLiveAlerts(payload));
  }
};

const addNewLiveAlertAction = (res) => async (dispatch) => {
  if (res.success) {
    dispatch(addProjectsLiveAlerts(res.data));
  }
};

export {
  getProjectsAction,
  getProjectsLastDataAction,
  getProjectsDataMinuteAction,
  getProjectsDataHourAction,
  addProjectDataHourAction,
  getProjectsDataDayAction,
  getProjectsAlertsAction,
  addNewAlertAction,
  addProjectLiveDataAction,
  addProjectDataMinuteAction,
  getProjectReportAction,
  getLiveAlertAction,
  addNewLiveAlertAction,
};
