import React, { Fragment } from "react";
import CustomCard from "../../shared/CustomCard";
import IndicatorList from "./IndicatorList";

export default function IndicatorListCard({ col = 4, config, data }) {
  const _title = config?.title;
  return (
    <Fragment>
      {config?.show && (
        <CustomCard col={col} title={_title}>
          <IndicatorList config={config} data={data} />
        </CustomCard>
      )}
    </Fragment>
  );
}
