export const theme = {
  dark: {
    bgPrimary: "#293647",
    bgSecondary: "#36455a",
    textPrimary: "#ffffff",
    textSecondary: "#ccc7c7",
  },
  light: {
    bgPrimary: "#ffffff",
    bgSecondary: "#eaeaea",
    textPrimary: "#2f2f2f",
    textSecondary: "#36455a",
  },
};
