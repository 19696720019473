import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";

export const exportToExcel = (options, jsonData) => {
  const { headers, fullMergeInfo, keys } = getHeadersAndMerge({
    projectName: options.project.name,
    pageName: options.subPages.name,
    arr: options.dataToExport,
  });

  let _jsonData = jsonData.map((item) => {
    let _item = [];
    _item.push(formatTime(item.time, options.interval));
    keys.forEach((key) => {
      _item.push(item?.rawJson[key]);
    });
    return _item;
  });

  let fileName = `${options.project.name}_${options.subPages.name}`;

  const dataWithHeaders = [...headers, ..._jsonData];
  

  const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);

  worksheet["!merges"] = fullMergeInfo;

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: "application/octet-stream" });

  saveAs(data, `${fileName}.xlsx`);
};

const getHeadersAndMerge = ({
  projectName,
  pageName,
  arr,
  date = "Time",
  dateUnit = "Hrs.",
}) => {
  const headers = [];
  const fullMergeInfo = [];
  let _keysCol = [];
  if (arr.length) {
    let _mergedCol = [];
    let _titleCol = [];
    let _unitCol = [];

    let start = 1;
    arr.forEach((element) => {
      let _allDatakeys = element.dataKeys
        ? element.dataKeys
        : element.items
        ? element.items
        : [];

      if (_allDatakeys?.length) {
        fullMergeInfo.push({
          s: { r: 2, c: start },
          e: { r: 2, c: start + _allDatakeys.length - 1 },
        });

        start += _allDatakeys.length;
      }

      _allDatakeys.forEach((key, i) => {
        if (i) {
          _mergedCol.push("");
        } else {
          _mergedCol.push(element.title);
        }

        _titleCol.push(key.title);
        _unitCol.push(key?.unit || key?.units || "");
        _keysCol.push(key?.value || "");
      });
    });

    _titleCol.unshift(date);
    _unitCol.unshift(dateUnit);
    _mergedCol.unshift("");

    headers.push(_mergedCol);
    headers.push(_titleCol);
    headers.push(_unitCol);
  }

  if (pageName) {
    headers.unshift([pageName]);
    fullMergeInfo.unshift({
      s: { r: 1, c: 0 },
      e: { r: 1, c: headers[headers.length - 1].length - 1 },
    });
  }

  if (projectName) {
    headers.unshift([projectName]);
    fullMergeInfo.unshift({
      s: { r: 0, c: 0 },
      e: { r: 0, c: headers[headers.length - 1].length - 1 },
    });
  }

  return { headers, fullMergeInfo, keys: _keysCol };
};

const formatTime = (time, type) => {
  let _time = moment(time).utc().format("DD-MM-YYYY HH:mm");
  // if (type === "HOURLY") {
  //   _time = _time.format("HH:mm");
  // } else if (type === "DAILY") {
  //   _time = _time.format("DD-MM-YYYY");
  // } else {
  //   _time = _time.format("DD-MM-YYYY HH:mm");
  // }
  return _time;
};
