import React, { useMemo, useState } from "react";
import { getBarChartSeries } from "../../utils/chartFunctions";
import ReactApexChart from "react-apexcharts";
import useAppColors from "../../hooks/useAppColors";

import { getLineChartGrouped } from "../../utils/chartFunctions";
import { useEffect } from "react";

// export default function BarChart({ config, data, extraOptions = {} }) {
//   const { appColors, theme } = useAppColors();
//   const _data = data;
//   const _yAxis = config.yAxis;
//   const _keys = config.items || [];
//   let { categories, series } = getBarChartSeries(_keys, _data);

//   const options = {
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         endingShape: "rounded",
//         borderRadius: 5,
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ["transparent"],
//     },
//     xaxis: {
//       labels: {
//         style: {
//           colors: appColors.textSecondary,
//         },
//       },
//       categories,
//     },
//     yaxis: {
//       title: {
//         text: _yAxis?.title,
//         style: {
//           color: appColors.textPrimary,
//         },
//       },
//       stepSize: 20,
//       min: _yAxis?.min,
//       max: _yAxis?.max,
//       labels: {
//         style: {
//           colors: appColors.textSecondary,
//         },
//       },
//     },
//     fill: {
//       type: "gradient",
//       gradient: {
//         shade: theme === "dark" ? "light" : "dark",
//         type: "horizontal",
//         opacityFrom: 0.9,
//         opacityTo: 1,
//       },
//     },
//     tooltip: {
//       theme: theme,
//     },
//     toolbar: {
//       show: false,
//     },
//     ...extraOptions,
//   };
//   return (
//     <ReactApexChart options={options} series={series} type="bar" height={250} />
//   );
// }

export function BarChart1({ config, data, extraOptions = {} }) {
  const { appColors, theme } = useAppColors();
  const _data = data;
  const _yAxis = config.yAxis;
  const _keys = config.items || [];
  let { categories, series } = getBarChartSeries(_keys, _data);

  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      labels: {
        style: {
          colors: appColors.textSecondary,
        },
      },
      categories,
    },
    yaxis: {
      title: {
        text: _yAxis?.title,
        style: {
          color: appColors.textPrimary,
        },
      },
      stepSize: 20,
      min: _yAxis?.min,
      max: _yAxis?.max,
      labels: {
        style: {
          colors: appColors.textSecondary,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: theme === "dark" ? "light" : "dark",
        type: "horizontal",
        opacityFrom: 0.9,
        opacityTo: 1,
      },
    },
    tooltip: {
      theme: theme,
    },
    toolbar: {
      show: false,
    },
    ...extraOptions,
  };
  console.log("series==>", series);
  return (
    <ReactApexChart options={options} series={series} type="bar" height={250} />
  );
}

export default function BarChart({
  config,
  data,
  height = 250,
  extraOptions = {},
}) {
  const { appColors, theme } = useAppColors();
  const _keys = useMemo(() => config.items || [], [config.items]);
  const _data = data;

  const _yAxis = config.yAxis;
  const _height = height;

  const [grouped, setGrouped] = useState([]);

  useEffect(() => {
    let grouped = getLineChartGrouped(_keys, _data);
    if (grouped?.length) {
      grouped = grouped.reverse();
      setGrouped(grouped);
    }
  }, [_data, _keys]);

  useEffect(() => {
    setGrouped((prev) => {
      if (prev.length < _data?.length) {
        let item = _data[0];
        let grouped = getLineChartGrouped(_keys, [item]);
        return [...prev, ...grouped];
      } else {
        return [...prev];
      }
    });
  }, [_data, _data?.length, _keys]);

  let colors = [];
  let series = [];
  _keys.forEach((item) => {
    colors.push(item?.color);
    series.push({
      name: item?.title,
      data: grouped.map((keys) => ({ x: keys.x, y: keys[item?.value] })),
      color: item?.color,
    });
  });

  let options = {
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      type: "datetime",
      title: {},
      labels: {
        style: {
          colors: appColors.textSecondary,
        },
      },
    },
    yaxis: {
      // stepSize: 20,
      title: {
        text: _yAxis?.title,
        style: {
          color: appColors.textPrimary,
        },
      },
      labels: {
        style: {
          colors: appColors.textSecondary,
        },
      },
    },

    tooltip: {
      theme: theme,
      x: {
        format: "dd MMM, HH:mm",
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        shade: theme === "dark" ? "light" : "dark",
        type: "horizontal",
        opacityFrom: 0.9,
        opacityTo: 1,
      },
    },
    legend: {
      position: "bottom",
    },
    ...extraOptions,
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={_height}
        width={"100%"}
      />
    </div>
  );
}
