import React, { Fragment } from "react";
import CustomCard from "../../shared/CustomCard";
import Gauge from "./Gauge";

export default function GaugeCard({ col = 4, config, data }) {
  const _data = data;
  const _title = config?.title;
  return (
    <Fragment>
      {config?.show && (
        <CustomCard col={col} title={_title}>
          <Gauge config={config} data={_data} />
        </CustomCard>
      )}
    </Fragment>
  );
}
