import React, { Fragment } from "react";
import CustomCard from "../shared/CustomCard";

export default function AlarmList({ config, alert, col = 4 }) {
  let _maxCount = config?.maxCount || 5;
  if (alert?.length > _maxCount) {
    alert = alert.slice(0, _maxCount);
  }

  const rowClass = (data) => {
    let classname = "";
    switch (data.type) {
      case 1:
        classname = "bg-red-500 text-white";

        break;
      case 2:
        classname = "bg-yellow-500 text-white";
        break;
      case 3:
        classname = "bg-primary text-white";
        break;

      default:
        break;
    }
    return classname;
  };
  return (
    <Fragment>
      {config?.show && (
        <CustomCard col={col} title={config?.title}>
          <div className={`scrollable-card ${alert?.length > 5 ? "pr-2" : ""}`}>
            {alert.map((item, i) => (
              <div
                key={i}
                className={`p-2 mt-2 border-round ${rowClass(
                  item
                )} md:block lg:flex justify-content-between flex-wrap`}
              >
                <span className="my-auto block">{item.description}</span>
                <small className="my-auto">{item.inTime}</small>
              </div>
            ))}
          </div>
        </CustomCard>
      )}
    </Fragment>
  );
}
