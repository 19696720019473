import React, { Fragment } from "react";
import AnalogGrid from "../charts/Analogs/AnalogGrid";
import IndicatorGrid from "../charts/Indicator/IndicatorGrid";

export default function Template11({ page, live }) {
  const _config = page?.config;
  const _live = live;
  const _analogs = _config?.analogs;
  const _indicators = _config?.indicators;
  return (
    <Fragment>
      <IndicatorGrid config={_indicators} data={_live} />
      <AnalogGrid config={_analogs} data={_live} />
    </Fragment>
  );
}
