import api from "../../services/api";
import endPoints from "../../services/endPoints";

export const uploadFile = async (file) => {
  if (file) {
    if (typeof file === "string") {
      return file;
    } else {
      const formData = new FormData();
      formData.append("file", file);
      const res = await api(
        "post",
        endPoints.UPLOAD_FILE,
        formData,
        {},
        { "Content-Type": "multipart/form-data" }
      );
      if (res.success) {
        return res.data.path;
      }
    }
  } else {
    return "";
  }
};
