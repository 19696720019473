import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loader: false,
  toastInfo: {},
  selectedTheme: "dark",
  news: [],
};

export const commonSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    showLoaderAction: (state) => {
      state.loader = true;
    },
    hideLoaderAction: (state) => {
      state.loader = false;
    },
    showToastAction: (state, action) => {
      state.toastInfo = action.payload;
    },
    setThemeAction: (state, action) => {
      state.selectedTheme = action.payload;
    },
    setNews: (state, action) => {
      state.news = action.payload;
    },
    addNews: (state, action) => {
      let _news = [...state.news];
      let _payload = action.payload;
      let index = _news.findIndex((item) => item._id === _payload._id);
      if (index >= 0) {
        _news[index] = _payload;
      } else {
        _news.unshift(_payload);
      }
      state.news = _news;
    },
    deleteNews: (state, action) => {
      let _news = [...state.news];
      let _id = action.payload._id;
      let index = _news.findIndex((item) => item._id === _id);
      if (index >= 0) {
        _news.splice(index, 1);
      }
      state.news = _news;
    },
  },
});
export const {
  showLoaderAction,
  hideLoaderAction,
  showToastAction,
  setThemeAction,
  setNews,
  addNews,
  deleteNews,
} = commonSlice.actions;
export default commonSlice.reducer;
