import React, { Fragment } from "react";
import Grid from "../../shared/Grid";
import CustomCard, { CustomCardSecondary } from "../../shared/CustomCard";

export default function AnalogGrid({ config, data }) {
  const _config = config;
  const _list = _config?.items;
  return (
    <Fragment>
      {_config?.show && (
        <Grid>
          {_list.map((item, i) => (
            <SingleAnalog key={i} config={item} data={data} />
          ))}
        </Grid>
      )}
    </Fragment>
  );
}

function SingleAnalog({ col = 4, config, data }) {
  let _data = data;
  const _title = config["title"];
  const _units = config["units"];
  const _value = _data && _data["rawJson"][config.value];

  return (
    <CustomCard col={col}>
      <CustomCardSecondary>
        <div className="flex justify-content-between">
          <div className="my-auto w-8">{_title}</div>
          <div className="my-auto w-4 flex">
            {_value ? (
              <span className="greenValue w-8 text-right pr-1">{_value}</span>
            ) : null}
            {_units ? (
              <span className="yellowUnit w-4 my-auto">{_units}</span>
            ) : null}
          </div>
        </div>
      </CustomCardSecondary>
    </CustomCard>
  );
}
