const appName = "iNDUS 5.1";
const appVersion = "2.0.1";
const appCopyright = `Copyright ©FLOVEL ${new Date().getFullYear()}`;

const website = "https://flovel.net/";
const facebook = "https://www.facebook.com/flovelenergy/";
const linkedin =
  "https://www.linkedin.com/company/flovel--energy-pvt--ltd/?originalSubdomain=in";
const whatsapp = "https://api.whatsapp.com/send?phone=919999555306";
const youtube = "https://www.youtube.com/user/flovelmail";

// const baseUrl = "http://localhost:1110/";
const baseUrl = "https://flovelapi.vinnisoft.org/";
const endPointUrl = baseUrl + "api/user";

const constants = {
  baseUrl,
  endPointUrl,
  appName,
  appVersion,
  appCopyright,
  website,
  facebook,
  linkedin,
  whatsapp,
  youtube,
};

export default constants;
