import React, { useState, useEffect } from "react";

import { CustomInput } from "../shared/AllInputs";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import formValidation from "../utils/validations";
import { getImageUrl, showFormErrors } from "../utils/commonFunctions";
import { updateProfileAction } from "../store/actions/userActions";
import { Image } from "primereact/image";
import iconUrl from "../assets/images/icon2.png";

export default function Profile() {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    if (profile) {
      setData({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        image: profile.image,
      });
    }
  }, [profile]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    image: "",
  });

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const onUpdate = () => {
    if (showFormErrors(data, setData)) {
      dispatch(updateProfileAction(data, setLoading));
    }
  };

  const onFileChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setData((prev) => ({ ...prev, image: files[0] }));
    }
  };

  const onRemoveImage = () => {
    setData((prev) => ({ ...prev, image: "" }));
  };

  return (
    <div className="col-12 md:col-7 grid m-0 p-0 profile">
      <input
        id="formImage"
        onChange={onFileChange}
        type="file"
        accept="image/*"
        hidden
      />
      <div className="col-12 flex">
        <Image
          src={
            data?.image
              ? typeof data?.image === "string"
                ? getImageUrl(data?.image)
                : URL.createObjectURL(data?.image)
              : iconUrl
          }
          alt="Image"
          width="90"
          height="90"
          preview
          icon="pi pi-eye"
        />
        <div className="my-auto mx-2">
          <label
            htmlFor="formImage"
            className="p-button p-component p-button-primary py-1 px-2 my-1  text-white"
          >
            Choose New
          </label>
          <br />
          <label
            onClick={onRemoveImage}
            className="p-button p-component p-button-secondary py-1 px-2 my-1 text-white"
          >
            Remove
          </label>
        </div>
      </div>

      <CustomInput data={profile} name="userName" disabled />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="firstName"
        col="6"
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="lastName"
        col="6"
      />
      <CustomInput data={data} onChange={handleChange} name="email" />
      <div className="col-12 flex justify-content-end">
        <Button
          loading={loading}
          onClick={onUpdate}
          severity="primary"
          label="Update"
        />
      </div>
    </div>
  );
}
