import React, { Fragment } from "react";
import Grid from "../shared/Grid";
import LineChartCard from "../charts/LineChart/LineChartCard";

export default function Template6({
  page,
  dataMinute = [],
  dataHour = [],
  dataDay = [],
}) {
  const _dataMinute = dataMinute;
  const _dataHour = dataHour;
  const _dataDay = dataDay;
  const _config = page?.config;
  const _lineChart = _config?.lineChart;

  return (
    <Fragment>
      <Grid>
        <LineChartCard
          height={300}
          config={_lineChart}
          dataMinute={_dataMinute}
          dataHour={_dataHour}
          dataDay={_dataDay}
          filters
        />
      </Grid>
    </Fragment>
  );
}
