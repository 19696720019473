import React, { Fragment, useMemo } from "react";
import Grid from "../shared/Grid";
import PieChartCard from "../charts/PieChart/PieChartCard";
import LineChartCard from "../charts/LineChart/LineChartCard";
import BarChartCard from "../charts/BarChart/BarChartCard";
import AnalogListCard from "../charts/Analogs/AnalogListCard";

export default function Template8({
  page,
  dataMinute = [],
  dataHour = [],
  dataDay = [],
  alert = [],
  live,
}) {
  const _live = live;
  const _dataMinute = dataMinute;
  const _dataHour = dataHour;
  const _dataDay = dataDay;
  const _config = page?.config;
  const _barChart = _config?.barChart;
  const _pieChart = _config?.pieChart;
  const _analogs = _config?.analogs;
  const _lineChart1 = _config?.lineChart1;
  const _lineChart2 = _config?.lineChart2;
  const _lineChart3 = _config?.lineChart3;

  let colCount = useMemo(() => {
    let count = 0;
    if (_lineChart1?.show) {
      count += 1;
    }
    if (_lineChart2?.show) {
      count += 1;
    }
    if (_lineChart3?.show) {
      count += 1;
    }
    return 12 / count;
  }, [_lineChart3?.show, _lineChart2?.show, _lineChart1?.show]);

  let colCount1 = useMemo(() => {
    let count = 12;
    if (_pieChart?.show) {
      count -= 4;
    }
    if (_analogs?.show) {
      count -= 4;
    }
    return count;
  }, [_pieChart?.show, _analogs?.show]);

  return (
    <Fragment>
      <Grid>
        <BarChartCard
          config={_barChart}
          dataHour={_dataHour}
          dataDay={_dataDay}
          col={colCount1}
        />
        <PieChartCard config={_pieChart} data={_live} />
        <AnalogListCard config={_analogs} data={_live} />
      </Grid>
      <Grid>
        <LineChartCard
          config={_lineChart1}
          dataMinute={_dataMinute}
          dataHour={_dataHour}
          dataDay={_dataDay}
          col={colCount}
        />
        <LineChartCard
          config={_lineChart2}
          dataMinute={_dataMinute}
          dataHour={_dataHour}
          dataDay={_dataDay}
          col={colCount}
        />
        <LineChartCard
          config={_lineChart3}
          dataMinute={_dataMinute}
          dataHour={_dataHour}
          dataDay={_dataDay}
          col={colCount}
        />
      </Grid>
    </Fragment>
  );
}
