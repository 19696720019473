import React from "react";
import IndicatorGrid from "../charts/Indicator/IndicatorGrid";

export default function Template10({ page, live }) {
  const _config = page?.config;
  const _live = live;
  const _indicators = _config?.indicators;
  return (
    <div>
      <IndicatorGrid config={_indicators} data={_live} />
    </div>
  );
}
