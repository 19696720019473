import { FirstletterUpperCase, equal, length, lessThan } from "./javascript";
import {
  emailValidation,
  firstLetterToUppercase,
  stringValidation,
} from "./regex";

const formValidation = (name, value, data, ignore = []) => {
  let formErrors = { ...data.formErrors };
  if (ignore.includes(name)) {
    if (formErrors[name]) formErrors[name] = "";
    return formErrors;
  }
  switch (name) {
    case "email":
      if (equal(length(value))) {
        formErrors[name] = `${FirstletterUpperCase(name)} is required!`;
      } else if (!emailValidation(value)) {
        formErrors[name] = `Please enter valid email!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "password":
    case "currentPassword":
    case "newPassword":
      if (equal(length(value))) {
        formErrors[name] = `${FirstletterUpperCase(name)} is required!`;
      } else if (lessThan(length(value), 6)) {
        formErrors[name] = `${FirstletterUpperCase(
          name
        )} should be 6 chracters!`;
      }
      // if (!passwordValidation(value)) {
      //   formErrors[
      //     name
      //   ] = `Please enter a password with 8-16 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character`;
      // }
      else {
        formErrors[name] = "";
      }
      break;
    case "startDate":
    case "endDate":
    case "range":
    case "dataToExport":
      if (equal(length(value))) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "userName":
    case "firstName":
    case "lastName":
      if (equal(length(value))) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else if (!stringValidation(value)) {
        formErrors[name] = `Unnecessary space or special chracter in word!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "project":
    case "subPages":
      if (!value.name) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;

    default:
      break;
  }
  return formErrors;
};

export default formValidation;
