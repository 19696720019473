import React, { useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";

import useProject from "../hooks/useProject";
import CustomMarker from "../shared/CustomMarker";

export default function Map() {
  const mapRef = useRef(null);
  const { rawProjects } = useProject();
  const { live } = useSelector((state) => state.project);

  const renderMarkers = () => {
    return (
      <>
        {rawProjects.map((item, i) => (
          <CustomMarker key={i} item={item} live={live} />
        ))}
      </>
    );
  };
  return (
    <MapContainer
      whenCreated={(map) => {
        mapRef.current = map;
      }}
      center={[20.5937, 78.9629]}
      zoom={3.4}
      className="map-container"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution={`Map`}
      />
      {renderMarkers()}
    </MapContainer>
  );
}
