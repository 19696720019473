import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PrivateRoutes } from "./allRoutes";
import Layout from "../layout/Layout";
import { isAuthenticated } from "../services/auth";

const Auth = ({ socket }) => {
  return isAuthenticated() ? (
    <Layout socket={socket}>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" />
  );
};

export const ProtectedRoutes = ({ socket }) => {
  return (
    <Routes>
      <Route element={<Auth socket={socket} />}>
        {PrivateRoutes.map((route, key) => (
          <Route key={key} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  );
};
