import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBMXtW5VGdoTF5agDvCEr1KJBj-wyCzIFU",
  authDomain: "iflovel.firebaseapp.com",
  projectId: "iflovel",
  storageBucket: "iflovel.appspot.com",
  messagingSenderId: "9486910826",
  appId: "1:9486910826:web:cb29b89ff074f6970a5113",
  measurementId: "G-XBX4GE60LR",
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    console.log("permission==>", permission);
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BEFF_Z5_4sytEJU683LaBswL4sN_7LG_NhPgaEQA189CoSzbxgz3TWwIW-UmYRXktNBJzbp1qaus0BToRn0Yx3Q",
      });
      localStorage.setItem("fcm", token);
      return token;
    }
    return;
  } catch (error) {
    console.log(error);
    return;
  }
};
