import React, { Fragment, useMemo } from "react";
import Grid from "../shared/Grid";

import PieChartCard from "../charts/PieChart/PieChartCard";
import LineChartCard from "../charts/LineChart/LineChartCard";
import BarChartCard from "../charts/BarChart/BarChartCard";

export default function Template4({
  page,
  dataMinute = [],
  dataHour = [],
  dataDay = [],
  live,
}) {
  const _live = live;
  const _dataMinute = dataMinute;
  const _dataHour = dataHour;
  const _dataDay = dataDay;
  const _config = page?.config;
  const _pieChart = _config?.pieChart;
  const _barChart1 = _config?.barChart1;
  const _barChart2 = _config?.barChart2;
  const lineChart1 = _config?.lineChart1;
  const lineChart2 = _config?.lineChart2;

  let colCount = useMemo(() => {
    let count = 0;
    if (_barChart1?.show) {
      count += 1;
    }
    if (_barChart2?.show) {
      count += 1;
    }
    if (_pieChart?.show) {
      return 8 / count;
    } else {
      return 12 / count;
    }
  }, [_pieChart?.show, _barChart1?.show, _barChart2?.show]);

  let colCount1 = useMemo(() => {
    let count = 12;
    if (lineChart1?.show) {
      count -= 8;
    }
    return count;
  }, [lineChart1?.show]);

  console.log("lineChart1==>", lineChart1);
  console.log("lineChart2==>", lineChart2);

  return (
    <Fragment>
      <Grid>
        <BarChartCard
          config={_barChart1}
          dataHour={_dataHour}
          dataDay={_dataDay}
          col={colCount}
        />
        <BarChartCard
          config={_barChart2}
          dataHour={_dataHour}
          dataDay={_dataDay}
          col={colCount}
        />
        <PieChartCard config={_pieChart} data={_live} />
      </Grid>
      <Grid>
        <LineChartCard
          col={8}
          config={lineChart1}
          dataMinute={_dataMinute}
          dataHour={_dataHour}
          dataDay={_dataDay}
        />
        <LineChartCard
          config={lineChart2}
          dataMinute={_dataMinute}
          dataHour={_dataHour}
          dataDay={_dataDay}
          type="area"
          col={colCount1}
        />
      </Grid>
    </Fragment>
  );
}
