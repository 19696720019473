import About from "../views/About";
import ChartReports from "../views/ChartReports";
import Dashboard from "../views/Dashboard";
import Help from "../views/Help";
import Home from "../views/Home";
import Map from "../views/Map";
import Profile from "../views/Profile";
import Project from "../views/Project";
import Reports from "../views/Reports";

export const PrivateRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/home",
    name: "Home",
    element: <Home />,
  },
  {
    path: "/map",
    name: "Projects Locations",
    element: <Map />,
  },
  {
    path: "/reports",
    name: "Reports",
    element: <Reports />,
  },
  {
    path: "/project/:projectId/:pageId",
    name: "Project",
    element: <Project />,
  },
  {
    path: "/reports/chart/:projectId/:config",
    name: "Project",
    element: <ChartReports />,
  },
  {
    path: "/help",
    name: "Help",
    element: <Help />,
  },
  {
    path: "/about",
    name: "About",
    element: <About />,
  },
  {
    path: "/profile",
    name: "Profile Settings",
    element: <Profile />,
  },
];
