import React, { useMemo } from "react";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import { capitalizeCamelCase } from "../utils/commonFunctions";

export const CustomInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 12,
  inputClass,
  disabled = false,
  type = "text",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputText
        id={name}
        name={name}
        value={value || data?.[name] || ""}
        type={type}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`input w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        disabled={disabled}
        {...props}
      />
    </InputLayout>
  );
};
export const CustomPassword = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 12,
  inputClass,
  disabled = false,
  type = "text",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Password
        id={name}
        name={name}
        value={value || data?.[name] || ""}
        type={type}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        inputClassName="w-full"
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        disabled={disabled}
        feedback={false}
        toggleMask
        {...props}
      />
    </InputLayout>
  );
};
export const CustomDropDown = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col = 7,
  inputClass,
  disabled = false,
  optionLabel = "name",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Dropdown
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full custom-dropdown shadow-5 ${
          inputClass ? inputClass : ""
        } ${errorMessage ? "p-invalid" : ""}`}
        optionLabel={optionLabel}
        disabled={disabled}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomCalander = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Calendar
        id={name}
        name={name}
        value={value || data?.[name] || ""}
        onChange={(e) => onChange && onChange({ ...e, name, value: e.value })}
        className={`w-full custom-calander shadow-5 ${
          inputClass ? inputClass : ""
        }`}
        dateFormat="dd/mm/yy"
        disabled={disabled}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomCheckbox = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      name=""
      extraClassName={extraClassName}
      errorMessage={data?.formErrors?.[name]}
    >
      <Checkbox
        id={name}
        name={name}
        inputId={label}
        checked={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.checked })
        }
        className={`checkbox ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
      <label htmlFor={label} className="ml-2">
        {label ? capitalizeCamelCase(label) : label}
      </label>
    </InputLayout>
  );
};

//For reports section
export const CustomCheckboxs = ({
  name,
  onChange,
  data,
  options = [],
  col = 7,
}) => {
  let values = useMemo(() => data[name], [data[name]]);
  const handleChange = (e) => {
    let _data = [...values];
    if (getActive(e)) {
      _data = _data.filter((item) => item.title !== e.title);
    } else {
      _data.push(e);
    }
    onChange({ name, value: _data });
  };
  const getActive = (e) => {
    return values.find((item) => item.title === e.title);
  };
  return (
    <InputLayout extraClassName col={col} name={name} data={data}>
      <div className="card shadow-5">
        {options.map((item, i) => (
          <div key={i} className="my-2 flex ">
            <i
              className={`my-auto pi ${
                getActive(item) ? "pi-check-square" : "pi-stop"
              } `}
              onClick={() => handleChange(item)}
            />
            <label
              className="my-auto ml-2 cursor-pointer"
              onClick={() => handleChange(item)}
            >
              {item?.title}
            </label>
          </div>
        ))}
      </div>
    </InputLayout>
  );
};
