import React from "react";
import { CustomCardSecondary } from "../../shared/CustomCard";

export default function IndicatorList({ config, data }) {
  const _list = config["items"];
  const _data = data && data["rawJson"];
  return (
    <div className={`scrollable-card ${_list.length > 5 ? "pr-2" : ""}`}>
      {_list.map((item, i) => (
        <CustomCardSecondary key={i} className="mt-2">
          <div className="flex justify-content-start">
            <div
              className={`indicator ${
                _data && _data[item.value] ? "red" : "green"
              }`}
            ></div>
            <div className="my-auto ml-3">{item.title}</div>
          </div>
        </CustomCardSecondary>
      ))}
    </div>
  );
}
