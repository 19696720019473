import React, { Fragment } from "react";
import Grid from "../shared/Grid";
import Gauges from "../charts/Gauges/Gauges";
import LineChartCard from "../charts/LineChart/LineChartCard";
import AnalogListCard from "../charts/Analogs/AnalogListCard";
import IndicatorListCard from "../charts/Indicator/IndicatorListCard";

export default function Template2({
  page,
  dataMinute = [],
  dataHour = [],
  dataDay = [],
  live,
}) {
  const _live = live;
  const _dataMinute = dataMinute;
  const _dataHour = dataHour;
  const _dataDay = dataDay;
  const _config = page?.config;

  const _gauges = _config?.gauges;
  const _indicators = _config?.indicators;
  const _analogs = _config?.analogs;
  const _lineChart = _config?.lineChart;

  return (
    <Fragment>
      <Gauges config={_gauges} data={_live} />
      <Grid>
        <AnalogListCard col={6} config={_analogs} data={_live} />
        <IndicatorListCard col={6} config={_indicators} data={_live} />
      </Grid>
      <Grid>
        <LineChartCard
          height={300}
          config={_lineChart}
          dataMinute={_dataMinute}
          dataHour={_dataHour}
          dataDay={_dataDay}
        />
      </Grid>
    </Fragment>
  );
}
