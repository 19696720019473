import React from "react";
import constants from "../constants";
import AppInfoSection from "../layout/AppInfoSection";

export default function About() {
  const headers = (
    <>
      <h1 className="text-center my-3">{constants.appName}</h1>
      <p className="text-justify line-height-3">
        In the era of technology transformation, FLOVEL provides a broad range
        of automation products within a single solution. This solution is
        designed to cater the all needs of power plant with a wide range of
        inbuild features. {constants.appName} is inhouse development of FLOVEL.
        It is designed in such a way to optimize the performance and deliver
        cost effective solultions to clients. This solution comprises the below
        major Add-Ons within it
      </p>
      <ul className="line-height-3">
        <li>Turbine Governor</li>
        <li>SCADA System</li>
        <li>Gateway System</li>
        <li>Remote Monitoring Solution</li>
        <li>Push Notifications</li>
        <li>Reporting On Various Stacks</li>
      </ul>
    </>
  );
  return <AppInfoSection headers={headers} />;
}
