const endPoints = {
  LOGIN: "/login",
  CHANGE_PASSWORD: "/change-password",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_FORGOT_PASSWORD: "/change-forgot-password",
  PROFILE: "/profile",
  FCM: "/fcm",
  PROJECT: "/project",
  PROJECT_LAST_RAWJSON: "/project/last-raw-json",
  PROJECT_RAWJSON: "/project/minute-raw-json",
  PROJECT_HOUR_RAWJSON: "/project/hour-raw-json",
  PROJECT_DAY_RAWJSON: "/project/day-raw-json",
  PROJECT_REPORT: "/project/report",
  NEWS: "/news",
  PROJECT_ALERT: "/project/alert",
  PROJECT_LIVE_ALERT: "/project/live-alert",
  UPLOAD_FILE: "/upload/file",
};
export default endPoints;
