import React, { Fragment, useMemo } from "react";
import AlarmList from "../charts/AlarmList";
import Grid from "../shared/Grid";
import PieChartCard from "../charts/PieChart/PieChartCard";
import LineChartCard from "../charts/LineChart/LineChartCard";
import BarChartCard from "../charts/BarChart/BarChartCard";
import AnalogListCard from "../charts/Analogs/AnalogListCard";
import IndicatorListCard from "../charts/Indicator/IndicatorListCard";

import BigAnalogs from "../charts/Analogs/BigAnalogs";

export default function Template1({
  page,
  dataMinute = [],
  dataHour = [],
  dataDay = [],
  alert = [],
  live,
}) {
  const _live = live;
  const _dataHour = dataHour;
  const _dataDay = dataDay;
  const _config = page?.config;
  const _pieChart = _config?.pieChart;
  const _lineChart = _config?.lineChart;
  const _barChart = _config?.barChart;
  const _indicators = _config?.indicators;
  const _analogs = _config?.analogs;
  const _alarmList = _config?.alarmList;
  const _singleAnalogs = _config?.singleAnalogs;

  const _extrLineChartOptions = {
    grid: {
      show: true,
      strokeDashArray: 4,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  let colCount = useMemo(() => {
    let count = 0;
    if (_lineChart?.show) {
      count += 1;
    }
    if (_barChart?.show) {
      count += 1;
    }
    if (_pieChart?.show) {
      return 8 / count;
    } else {
      return 12 / count;
    }
  }, [_pieChart?.show, _lineChart?.show, _barChart?.show]);

  return (
    <Fragment>
      <Grid>
        <PieChartCard config={_pieChart} data={_live} />
        <LineChartCard
          config={_lineChart}
          dataHour={_dataHour}
          dataDay={_dataDay}
          extraOptions={_extrLineChartOptions}
          filters
          col={colCount}
        />
        <BarChartCard
          config={_barChart}
          dataHour={_dataHour}
          dataDay={_dataDay}
          filters
          col={colCount}
        />
      </Grid>
      <BigAnalogs config={_singleAnalogs} data={_live} />
      <Grid>
        <IndicatorListCard config={_indicators} data={_live} />
        <AnalogListCard config={_analogs} data={_live} />
        <AlarmList config={_alarmList} alert={alert} />
      </Grid>
    </Fragment>
  );
}
