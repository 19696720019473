import React from "react";
import { useSelector } from "react-redux";
import useProject from "../hooks/useProject";
import Template1 from "../templates/Template1";
import Template2 from "../templates/Template2";
import Template3 from "../templates/Template3";
import Template4 from "../templates/Template4";
import Template5 from "../templates/Template5";
import Template6 from "../templates/Template6";
import Template7 from "../templates/Template7";
import Template8 from "../templates/Template8";
import Template9 from "../templates/Template9";
import Template10 from "../templates/Template10";
import Template11 from "../templates/Template11";
import { Link } from "react-router-dom";
import AppInfoSection from "../layout/AppInfoSection";

export default function Project() {
  const { project, page } = useProject();
  const { liveAlert, dataMinute, alert, live, dataHour, dataDay } = useSelector(
    (state) => state.project
  );

  const _live = live[project?._id];
  const _dataMinute = dataMinute[project?._id];
  const _dataHour = dataHour[project?._id];
  const _dataDay = dataDay[project?._id];

  const _liveAlert = liveAlert[project?._id];
  const _alert = alert[project?._id];

  const getPageAndProject = () => {
    if (page) {
      switch (page.templateId) {
        case "TEMPLATE_1":
          return (
            <Template1
              project={project}
              page={page}
              alert={_liveAlert}
              live={_live}
              dataMinute={_dataMinute}
              dataHour={_dataHour}
              dataDay={_dataDay}
            />
          );
        case "TEMPLATE_2":
          return (
            <Template2
              project={project}
              page={page}
              alert={_liveAlert}
              live={_live}
              dataMinute={_dataMinute}
              dataHour={_dataHour}
              dataDay={_dataDay}
            />
          );
        case "TEMPLATE_3":
          return (
            <Template3
              project={project}
              page={page}
              alert={_liveAlert}
              live={_live}
              dataMinute={_dataMinute}
              dataHour={_dataHour}
              dataDay={_dataDay}
            />
          );
        case "TEMPLATE_4":
          return (
            <Template4
              project={project}
              page={page}
              alert={_liveAlert}
              live={_live}
              dataMinute={_dataMinute}
              dataHour={_dataHour}
              dataDay={_dataDay}
            />
          );
        case "TEMPLATE_5":
          return <Template5 project={project} alert={_alert} />;
        case "TEMPLATE_6":
          return (
            <Template6
              project={project}
              page={page}
              alert={_liveAlert}
              live={_live}
              dataMinute={_dataMinute}
              dataHour={_dataHour}
              dataDay={_dataDay}
            />
          );
        case "TEMPLATE_7":
          return (
            <Template7
              project={project}
              page={page}
              alert={_liveAlert}
              live={_live}
              dataMinute={_dataMinute}
              dataHour={_dataHour}
              dataDay={_dataDay}
            />
          );
        case "TEMPLATE_8":
          return (
            <Template8
              project={project}
              page={page}
              alert={_liveAlert}
              live={_live}
              dataMinute={_dataMinute}
              dataHour={_dataHour}
              dataDay={_dataDay}
            />
          );
        case "TEMPLATE_9":
          return <Template9 project={project} page={page} live={_live} />;
        case "TEMPLATE_10":
          return <Template10 project={project} page={page} live={_live} />;
        case "TEMPLATE_11":
          return <Template11 project={project} page={page} live={_live} />;
        default:
          return <h2>Loading...</h2>;
      }
    } else {
      return (
        <AppInfoSection>
          <h1>Page Not Found!</h1>
          <Link to="/dashboard">
            <div>Go To Dashboard</div>
          </Link>
          <br />
        </AppInfoSection>
      );
    }
  };

  return <>{getPageAndProject()}</>;
}
